<template>
	<v-row>
		<v-col class="px-2">
			<hr-card dense :title="$t('titles.colleagues')" :centered-content="!isInitialized || !colleagues.length"
					 content-class="px-3 pb-3">
				<v-progress-circular style="min-height: 161px" v-if="!isInitialized" indeterminate color="primary"></v-progress-circular>
				<div style="min-height: 60px" class="fill-height d-flex align-center justify-center mb-4" v-else-if="!colleagues.length">
					{{ $t("alerts.info.noData") }}
				</div>
				<v-row v-else class="align-center flex-wrap pt-4 frp-scrollbar pb-5 pr-3 pl-2"
					   style="row-gap: 16px; max-height: 142px; overflow-y: auto; scroll-snap-type: y mandatory;">
					<v-col cols="12" sm="4" md="3" lg="2" class="d-flex"
						   style="align-self: start; scroll-snap-align: start;"
						   :style="`min-width: ${$vuetify.breakpoint.lgAndUp ? '430px' : ($vuetify.breakpoint.mdAndUp ? '390px' : '320px')}`"
						   v-for="colleague in colleagues" :key="colleague.id">
						<frp-avatar :id="colleague.pictureFileId" size="111" class="mr-3"></frp-avatar>
						
						<div class="d-flex flex-column justify-start" style="row-gap: 8px">
							<frp-router-link-btn :to="{ name: RouteNames.EMPLOYEE, params: { id: colleague.id } }">
								<span class="font-weight-bold" :style="`font-size: 14px; color: ${colors.blue.base}`">
									{{ `${colleague.lastName} ${colleague.firstName}` }}
								</span>
							</frp-router-link-btn>
							<span :style="`font-size: 12px; font-weight: 600; color: ${colors.primary.darken1}`" style="line-height: 16.3px">
								{{ colleague.position }}
							</span>
							<span v-if="colleague.extensionPhone" :style="`font-size: 12px; color: ${colors.primary.darken1}`">
								{{ `${$t("content.phone")}: ${colleague.extensionPhone}` }}
							</span>
							<span v-if="colleague.status" :style="`font-size: 12px; color: ${colors.grey.base}`">
								{{ colleague.status }}
							</span>
						</div>
					</v-col>
				</v-row>
			</hr-card>
		</v-col>
	</v-row>
</template>

<script>
import FrpAvatar from "@/components/common/FrpAvatar.vue";
import FrpRouterLinkBtn from "Components/buttons/FrpRouterLinkBtn";
import HrCard from "Components/cards/HrCard";
import authorizationMixin from "Mixins/authorizationMixin";
import colorsMixin from "Mixins/colorsMixin";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import { RouteNames } from "Router/hr/routes";
import { actionTypes } from "Store/hr/modules/employees/types";
import storeManager from "Store/manager";
import { createNamespacedHelpers } from "vuex";

const namespace = storeManager.hr.employee.colleagues.namespace;
const { mapState, mapActions } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin, authorizationMixin, storeModuleBasedPage],
	data() {
		return {
			RouteNames,
			namespace
		};
	},
	computed: {
		...mapState({
			isInitialized: state => state.isInitialized,
			colleagues: state => state.colleagues
		})
	},
	methods: {
		...mapActions({
			initializeStore: actionTypes.initialize
		})
	},
	created() {
		this.initializeStore();
	},
	components: { FrpAvatar, FrpRouterLinkBtn, HrCard }
};
</script>
