import NotDefinedException from "@/exceptions/notDefinedException";

export const getFileExtension = (name: string): string | null => {
	const res = name.match(/\.[^.\\\/:*?"<>|\r\n]+$/);
	
	return res && res[0];
}

export const dataURLtoFile = (dataurl: string, filename: string) => {
	if(!dataurl)
		throw new NotDefinedException("dataurl");
	
	let arr = dataurl.split(","),
		mime = arr[0].match(/:(.*?);/)![1],
		bstr = atob(arr[arr.length - 1]),
		n = bstr.length,
		u8arr = new Uint8Array(n);
	
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}
	
	return new File([u8arr], filename, { type: mime });
}

export const blobToDataURL = (blob: Blob) => {
	return URL.createObjectURL(blob);
}
