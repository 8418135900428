import UserState from "@/store/bar/modules/user/types/userState";
import { actionTypes, getterTypes, mutationTypes, namespace } from "@/store/bar/modules/user/types";
import BaseMixinBuilder from "@/store/shared/base";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import SecurityService from "@/services/auth/securityService";
import AbortService from "@/services/abortService";
import { BarController } from "@/api/bar";
import PermissionsResolver from "@/api/authorization/permissionsResolver";
import { BarRolesTypeEnum } from "@/store/bar/modules/user/types/BarRolesTypeEnum";

const abortService = new AbortService();
const barController = new BarController(abortService);

const permissionsResolver = new PermissionsResolver();
const securityService = new SecurityService();

const baseMixin = (new BaseMixinBuilder(abortService)).build();

const state = new UserState();

const getters = <GetterTree<UserState, any>>{
	[getterTypes.isBarUserAdministrator]: state => {
		return state.user.roles.includes(BarRolesTypeEnum.ADMINISTRATOR);
	},
	[getterTypes.isBarUserProjectFinancialControlDepartmentManager]: state => {
		return state.user.roles.includes(BarRolesTypeEnum.PROJECT_FINANCIAL_CONTROL_DEPARTMENT_MANAGER);
	},
	[getterTypes.isBarUserBorrower]: state => {
		return state.user.roles.includes(BarRolesTypeEnum.BORROWER);
	},
	[getterTypes.isBarUserCuratorsDepartmentManager]: state => {
		return state.user.roles.includes(BarRolesTypeEnum.CURATORS_DEPARTMENT_MANAGER);
	},
	[getterTypes.isBarUserLawyerManager]: state => {
		return state.user.roles.includes(BarRolesTypeEnum.LAWYER_MANAGER);
	},
	[getterTypes.userId]: state => {
		return state.user.employeeId;
	},
};

const actions = <ActionTree<UserState, any>>{
	...baseMixin.actions,
	async [actionTypes.initialize]({ commit, dispatch }) {
		await dispatch(actionTypes.initializeBase);
		
		await dispatch(actionTypes.fetchUser);
		
		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.fetchUser]({ commit }) {
		commit(mutationTypes.SET_IS_USER_LOADING, true);
		
		try {
			let user = await barController.getCurrentUser();
			
			commit(mutationTypes.SET_USER, user);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_USER_LOADING, false);
		}
	},
	async [actionTypes.fetchPermissions]({ commit }) {
		commit(mutationTypes.SET_IS_PERMISSIONS_LOADING, true);
		
		try {
			let permissions = await permissionsResolver.resolve();
			
			commit(mutationTypes.SET_PERMISSIONS, permissions);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_PERMISSIONS_LOADING, true);
		}
	}
};

const mutations = <MutationTree<UserState>>{
	...baseMixin.mutations,
	[mutationTypes.SET_IS_USER_LOADING](state, value) {
		state.isUserLoading = value;
	},
	[mutationTypes.SET_IS_PERMISSIONS_LOADING](state, value) {
		state.isPermissionsLoading = value;
	},
	[mutationTypes.SET_USER](state, value) {
		state.user = value;
	},
	[mutationTypes.SET_PERMISSIONS](state, value) {
		state.permissions = value;
	}
};

export {
	namespace, state, actions, mutations, getters
};

const userModule = {
	namespace, state, actions, mutations, namespaced: true, getters
};

export default userModule;
