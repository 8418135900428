<template>
	<v-app v-if="isInitialized">
		<v-navigation-drawer app class="bar-navigation" v-if="$vuetify.breakpoint.smAndDown" v-model="drawer" :width="300" clipped>
			<v-list class="pa-0">
				<v-list-item class="bar-list-item px-8" v-for="(item, i) in menuItems"
							 :value="i"
							 :key="i"
							 :href="item.href">
					<v-list-item-title v-text="item.text"></v-list-item-title>
				</v-list-item>
			</v-list>
		</v-navigation-drawer>
		
		<v-app-bar height="52"
				   elevation="0"
				   fixed
				   class="flex-grow-0"
				   dark
				   color="primary lighten-1">
			<v-app-bar-nav-icon v-if="$vuetify.breakpoint.smAndDown" @click="drawer = !drawer"></v-app-bar-nav-icon>
			
			<v-list class="pa-0 bar-menu" v-if="$vuetify.breakpoint.mdAndUp">
				<v-list-item class="bar-list-item px-8" v-for="(item, i) in menuItems"
							 :value="i"
							 :key="i"
							 :href="item.href">
					<v-list-item-title v-text="item.text"></v-list-item-title>
				</v-list-item>
			</v-list>
			
			<v-spacer></v-spacer>
			
			<v-menu bottom
					nudge-bottom="20"
					max-width="250"
					offset-y
					v-if="isInitialized && $vuetify.breakpoint.mdAndUp"
					content-class="elevation-5 menu-transition">
				<template v-slot:activator="{ on, attrs }">
					<v-btn max-width="300"
						   class="text-none profile-btn text-left justify-end user-menu pr-2 pl-5 text-subtitle-2 white--text"
						   style="border: none"
						   v-ripple="false"
						   v-bind="attrs"
						   v-on="on" text outlined>
						<span class="text-truncate">
							{{ formatFullName(user) }}
						</span>
						<frp-icon class="ml-2" src="ico_bod-chevron-down" :color="colors.grey.lighten4"></frp-icon>
						<frp-icon class="ml-2" src="ico_help" :color="colors.warning.base"></frp-icon>
					</v-btn>
				</template>
				<v-list>
					<v-list-item @click="logout">
						<v-icon :color="colors.primary.lighten1">mdi-logout</v-icon>
						<v-list-item-title class="ml-2">{{ $t("buttons.logout") }}</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
		</v-app-bar>
		
		<v-main>
			<v-container fluid
						 class="pt-13 px-0 primary lighten-4 full-height flex-nowrap align-start flex-column justify-start"
						 :class="{ 'pb-16': pageMode === PageModeType.OK }"
						 fill-height>
				<template v-if="pageMode === PageModeType.OK">
					<template v-if="$route.name && isBreadcrumbsExists">
						<bar-breadcrumbs :namespace="storeManager.bar.breadcrumbs.namespace"></bar-breadcrumbs>
					</template>
					<router-view :key="$route.meta.key || $route.name"/>
				</template>
				<frp-page-not-found v-else-if="pageMode === PageModeType.PAGE_NOT_FOUND"></frp-page-not-found>
				<frp-access-denied-error v-else-if="pageMode === PageModeType.ACCESS_DENIED"></frp-access-denied-error>
				<frp-alerts left-offset="0"></frp-alerts>
			</v-container>
		</v-main>
	</v-app>
	
	<v-app v-else>
		<v-main>
			<frp-center-content-layout>
				<frp-spinner-loader color="blue"/>
			</frp-center-content-layout>
		</v-main>
	</v-app>
</template>

<script>
import BarBreadcrumbs from "@/components/common/BarBreadcrumbs.vue";
import FrpCenterContentLayout from "@/components/layouts/FrpCenterContentLayout.vue";
import FrpSpinnerLoader from "@/components/loaders/common/FrpSpinnerLoader.vue";
import { RouteNames } from "@/router/bar/routes";
import { formatFullName } from "@/utils/formatting";
import FrpAlerts from "Components/alerts/FrpAlerts";
import { i18n } from "Plugins/index";
import { PageModeType } from "Store/bar/types/pageModeType";
import FrpPageNotFound from "Views/errors/FrpPageNotFound";
import FrpAccessDeniedError from "Views/errors/FrpAccessDeniedError";
import storeManager from "Store/manager";
import rootTypes from "Store/bar/types";
import { mapState, mapMutations } from "vuex";
import FrpBtn from "Components/buttons/FrpBtn";
import FrpIcon from "Components/icon/FrpIcon";
import authorizationMixin from "Mixins/authorizationMixin";
import colorsMixin from "Mixins/colorsMixin";
import SecurityService from "Services/auth/securityService";
import { actionTypes, namespace } from "Store/bar/modules/user/types";
import { createNamespacedHelpers } from "vuex";

const securityService = new SecurityService();
const { mapState: mapUserState, mapActions: mapUserActions } = createNamespacedHelpers(namespace);

const barBreadcrumbsHelpers = createNamespacedHelpers(storeManager.bar.breadcrumbs.namespace);

export default {
	mixins: [colorsMixin, authorizationMixin],
	metaInfo: {
		title: i18n.t("metaTitles.bankAccountsRegister")
	},
	data() {
		return {
			drawer: false,
			menuItems: [
				{
					text: this.$t("navigation.otherModules"),
					permissions: [],
					href: `${process.env.VUE_APP_OLK_BASE_URL}/apps`
				}
			],
			storeManager,
			PageModeType,
			RouteNames,
			formatFullName
		};
	},
	computed: {
		...mapState({
			pageMode: state => state.pageMode,
			isAppLoading: state => state.isAppLoading
		}),
		...mapUserState({
			isInitialized: state => state.isInitialized,
			user: state => state.user
		}),
		...barBreadcrumbsHelpers.mapState({
			breadcrumbs: state => state.items,
			isLoading: state => state.isLoading
		}),
		isBreadcrumbsExists() {
			switch (this.$route.name) {
				case RouteNames.BANK_ACCOUNT_APPLICATION:
				case RouteNames.BANK_ACCOUNT_APPLICATION_CREATE:
				case RouteNames.BANK_ACCOUNT_APPLICATION_UPDATE:
				case RouteNames.BANK_ACCOUNT:
				case RouteNames.BANK_ACCOUNT_CREATE:
					return true;
				default:
					return false;
			}
		}
	},
	methods: {
		...mapMutations({
			resetPageMode: rootTypes.mutationTypes.RESET_PAGE_MODE
		}),
		...mapUserActions({
			initializeStore: actionTypes.initialize
		}),
		logout() {
			this.signOut();
		}
	},
	async created() {
		if(!this.isInitialized)
			await this.initializeStore();
	},
	watch: {
		"$route"(to, from) {
			if(!to || !from)
				return;
			
			if(to.name !== from.name)
				this.resetPageMode();
		}
	},
	components: {
		FrpCenterContentLayout,
		FrpSpinnerLoader,
		BarBreadcrumbs,
		FrpAlerts,
		FrpPageNotFound,
		FrpAccessDeniedError,
		FrpBtn,
		FrpIcon
	}
};
</script>

<style scoped lang="scss">
.bar-menu {
  display: flex;
  background: none;
  gap: 24px;

  a {
	padding: 0 20px !important;
  }

  a::before {
	opacity: 0;
  }

  .v-list-item {
	min-height: 36px !important;
  }
}

.bar-navigation {
  background-color: var(--v-white-base) !important;
}

.bar-list-item {
  .v-list-item__title {
	font-size: 0.875rem !important;
	font-family: "Ubuntu", sans-serif !important;
	letter-spacing: 0.0015em !important;
  }
}
</style>
