<template>
	<img-uploader field="img"
				  ref="uploader"
				  class="frp-image-cropper"
				  @crop-success="onCropSuccess"
				  @crop-upload-fail="onCropUploadFail"
				  @src-file-set="onSrcFileSet"
				  :value="value"
				  @input="$emit('update:value', $event)"
				  :width="400"
				  :height="400"
				  langType="ru"
				  :submit-btn-loading="loading"
				  noSquare
				  img-format="jpg">
	</img-uploader>
</template>

<script>
import ImgUploader from "@/components/common/vueImageCropUpload/ImgUploader.vue";
import alertService from "@/store/modules/alerts/services/alertService";
import FrpIcon from "Components/icon/FrpIcon";
import colorsMixin from "Mixins/colorsMixin";

export default {
	mixins: [colorsMixin],
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		value: Boolean,
		loading: Boolean
	},
	data() {
		return {
			isOpened: false,
			fileName: false
		};
	},
	methods: {
		onCropSuccess(imgDataUrl, field) {
			const i = new Image();
			
			i.src = imgDataUrl;
			
			this.$emit("success", imgDataUrl, this.fileName);
		},
		onCropUploadFail(status, field) {
			console.error("status: ", status, "\nfield: " + field);
			alertService.addCustomInfo("Ошибка при загрузке изображения");
		},
		onSrcFileSet(name, type, size) {
			this.fileName = name;
		}
	},
	components: {
		ImgUploader,
		FrpIcon
	}
};
</script>

<style scoped lang="scss">
.frp-image-cropper:deep {
  .vicp-wrap {
	width: 485px !important;
  }

  .vicp-crop {
	display: flex;
  }

  .vicp-crop-right {
	width: 100%;
  }

  .vicp-preview {
	display: flex;
	align-items: center;
	justify-content: center;
	height: calc(100% - 58px) !important;
  }

  .vicp-preview-item span {
	display: none !important;
  }
}
</style>
