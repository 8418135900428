import AbortService from "@/services/abortService";
import { BarController } from "@/api/bar";
import FormMixinBuilder from "@/store/shared/form";
import SnapshotMixinBuilder from "@/store/shared/snapshot";
import SnapshotOptions from "@/store/shared/snapshot/snapshotOptions";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import BaseMixinBuilder from "@/store/shared/base";
import BankAccountApplicationDocumentsState
	from "@/store/bar/modules/bankAccountApplication/modules/documents/types/bankAccountApplicationDocumentsState";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import BankAccountApplicationState from "@/store/bar/modules/bankAccountApplication/types/bankAccountApplicationState";
import { namespace, actionTypes, getterTypes, mutationTypes } from "@/store/bar/modules/bankAccountApplication/modules/documents/types";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import { BankAccountApplicationDocumentTypeEnum } from "@/store/bar/types/BankAccountApplicationDocumentTypeEnum";
import { ElectronicDocumentStatusTypeEnum } from "@/store/bar/types/ElectronicDocumentStatusTypeEnum";
import { resolveNestedState } from "@/utils/vuexModules";
import storeManager from "@/store/manager";
import { cloneDeep } from "lodash";
import { DictionariesController } from "@/api/bar/dictionaries";
import FileMeta from "@/store/shared/storage/types/fileMeta";
import stateSnapshotKeys from "@/store/shared/snapshot/keys";
import ApiElectronicDocumentStatus from "@/api/bar/types/dictionaries/apiElectronicDocumentStatus";
import { BarStorageController } from "@/api/barStorage";
import { saveAs } from "file-saver";
import { ApiDocumentResponse, ApiDocumentResponseHelper } from "@/api/bar/types/apiDocumentResponse";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import { Permissions } from "@/constants/permissions";
import PermissionsService from "@/services/permissionsService";
import { DocumentResponseMapper } from "@/store/bar/types/documentResponse";
import { ApiUpdateDocumentStatusRequestMapper } from "@/api/bar/types/apiUpdateDocumentStatusRequest";
import { getFileExtension } from "@/utils/file";
import { ApiUpdateDocumentCommentRequestMapper } from "@/api/bar/types/apiUpdateDocumentCommentRequest";
import { isAcceptedOrDeclined } from "@/store/bar/helpers/isAcceptedOrDeclined";
import { isNotInProgressAndUnknown } from "@/store/bar/helpers/isNotInProgressAndUnknown";

const abortService = new AbortService();

const barController = new BarController(abortService);
const dictionariesController = new DictionariesController(abortService);
const barStorageController = new BarStorageController(abortService);

const permissionsService = new PermissionsService();

const formMixin = (new FormMixinBuilder()).build();
const snapshotMixin = (new SnapshotMixinBuilder({
	options: [
		new SnapshotOptions({
			key: stateSnapshotKeys.LAST_SAVED,
			fields: ["documentFileInfoItems"]
		})
	]
})).build();

class DefaultStateBuilder {
	constructor() {
	}
	
	build() {
		return new BankAccountApplicationDocumentsState(
			formMixin.state(),
			snapshotMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<BankAccountApplicationDocumentsState, any>>{
	...formMixin.getters,
	...snapshotMixin.getters,
	[getterTypes.selectableElectronicDocumentStatuses]: (state, getters, rootState) => (disabled: boolean) => {
		const { electronicDocumentStatuses } = resolveNestedState<BankAccountApplicationState>(rootState,
			storeManager.bar.bankAccountApplication.namespace);
		
		return electronicDocumentStatuses.reduce(
			(acc, x) => {
				if(isNotInProgressAndUnknown(x.code)) {
					acc.push({
						...x,
						disabled: isAcceptedOrDeclined(x.code) && disabled
					});
				}
				
				return acc;
			},
			[] as any
		);
	},
	[getterTypes.selectablePaperDocumentStatuses]: (state, getters, rootState) => {
		const { paperDocumentStatuses } = resolveNestedState<BankAccountApplicationState>(rootState,
			storeManager.bar.bankAccountApplication.namespace);
		
		return paperDocumentStatuses.filter(x => isNotInProgressAndUnknown(x.code));
	},
	[getterTypes.hasAccounts]: (state, getters, rootState) => {
		const { editableItem } = resolveNestedState<BankAccountApplicationState>(rootState,
			storeManager.bar.bankAccountApplication.namespace);
		
		return !!editableItem.accounts.length;
	}
};

const actions = <ActionTree<BankAccountApplicationDocumentsState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...formMixin.actions,
	...snapshotMixin.actions,
	async [actionTypes.initialize]({ rootState, dispatch, commit }) {
		await dispatch(actionTypes.initializeBase);
		
		await dispatch(actionTypes.getDocuments);
		
		commit(mutationTypes.SET_IS_INITIALIZED, true);
		commit(mutationTypes.SET_STATE_SNAPSHOT, stateSnapshotKeys.LAST_SAVED);
	},
	async [actionTypes.getDocuments]({ commit, rootState, state }) {
		const documentReadPermissions = [
			Permissions.BAR_APPLICATION_DOWNLOAD_DOCUMENTS_READ,
			Permissions.BAR_APPLICATION_EDIT_DOCUMENTS_READ,
			Permissions.BAR_APPLICATION_UPLOAD_DOCUMENTS_READ
		];
		
		if(await permissionsService.check(documentReadPermissions)) {
			commit(mutationTypes.SET_IS_DOCUMENTS_LOADING, true);
			
			try {
				const { id } = rootState.route.params;
				const { editableItem } = resolveNestedState<BankAccountApplicationState>(rootState,
					storeManager.bar.bankAccountApplication.namespace);
				const accountsIds = editableItem.accounts.map(x => x.id);
				const tasks = [
					barController.getInformationalLetterDocumentFileInfo(id),
					barController.getDirectWithdrawalAgreementDocumentFileInfo(id),
					barController.getPaymentAcceptAgreementDocumentFileInfo(id),
					barController.getAccountCorrectionAgreementDocumentFileInfo(id)
				];
				
				const documentFileInfoItems = await Promise.all(tasks);
				
				commit(mutationTypes.SET_DOCUMENT_FILE_INFO_ITEMS, documentFileInfoItems.map(x => DocumentResponseMapper.map(x)));
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				commit(mutationTypes.SET_IS_DOCUMENTS_LOADING, true);
			}
		}
	},
	async [actionTypes.updateDocumentComment]({ commit, dispatch, rootState, state }, { index, type }) {
		commit(mutationTypes.SET_DOCUMENT_FILE_INFO_ITEMS_ITEM_IS_COMMENT_CHANGING, { index, value: true });
		
		try {
			const payload = ApiUpdateDocumentCommentRequestMapper.map(state.documentFileInfoItems.find(x => x.type === type)!);
			const { id } = rootState.route.params;
			
			payload.explanation = !payload.explanation ? " " : payload.explanation;
			
			switch (type) {
				case BankAccountApplicationDocumentTypeEnum.ACCOUNT_CORRECTION_AGREEMENT:
					await barController.updateAccountCorrectionAgreementDocumentComment(id, payload);
					break;
				case BankAccountApplicationDocumentTypeEnum.INFORMATIONAL_LETTER:
					await barController.updateInformationalLetterDocumentComment(id, payload);
					break;
				case BankAccountApplicationDocumentTypeEnum.PAYMENT_ACCEPT_AGREEMENT:
					await barController.updatePaymentAcceptAgreementDocumentComment(id, payload);
					break;
				case BankAccountApplicationDocumentTypeEnum.DIRECT_WITHDRAWAL_AGREEMENT:
					await barController.updateDirectWithdrawalAgreementDocumentComment(id, payload);
					break;
			}
			
			await dispatch(actionTypes.getDocuments);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_DOCUMENT_FILE_INFO_ITEMS_ITEM_IS_COMMENT_CHANGING, { index, value: false });
			commit(mutationTypes.SET_STATE_SNAPSHOT, stateSnapshotKeys.LAST_SAVED);
		}
	},
	async [actionTypes.updateDocumentStatus]({ commit, dispatch, rootState, state }, { index, type }) {
		commit(mutationTypes.SET_DOCUMENT_FILE_INFO_ITEMS_ITEM_IS_STATUS_CHANGING, { index, value: true });
		
		try {
			const payload = ApiUpdateDocumentStatusRequestMapper.map(state.documentFileInfoItems.find(x => x.type === type)!);
			const { id } = rootState.route.params;
			
			switch (type) {
				case BankAccountApplicationDocumentTypeEnum.ACCOUNT_CORRECTION_AGREEMENT:
					await barController.updateAccountCorrectionAgreementDocumentStatus(id, payload);
					break;
				case BankAccountApplicationDocumentTypeEnum.INFORMATIONAL_LETTER:
					await barController.updateInformationalLetterDocumentStatus(id, payload);
					break;
				case BankAccountApplicationDocumentTypeEnum.PAYMENT_ACCEPT_AGREEMENT:
					await barController.updatePaymentAcceptAgreementDocumentStatus(id, payload);
					break;
				case BankAccountApplicationDocumentTypeEnum.DIRECT_WITHDRAWAL_AGREEMENT:
					await barController.updateDirectWithdrawalAgreementDocumentStatus(id, payload);
					break;
				
			}
			
			await dispatch(actionTypes.getDocuments);
			
			commit(mutationTypes.SET_DOCUMENT_FILE_INFO_ITEMS_ITEM_ELECTRONIC_STATUS, { index, value: payload.electronicDocumentStatus });
			commit(mutationTypes.SET_DOCUMENT_FILE_INFO_ITEMS_ITEM_PAPER_STATUS, { index, value: payload.paperDocumentStatus })
			
			commit(mutationTypes.DOCUMENT_STATUS_UPDATED);
			
			return true;
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
			
			return false;
		} finally {
			commit(mutationTypes.SET_DOCUMENT_FILE_INFO_ITEMS_ITEM_IS_STATUS_CHANGING, { index, value: false });
			commit(mutationTypes.SET_STATE_SNAPSHOT, stateSnapshotKeys.LAST_SAVED);
		}
	},
	async [actionTypes.uploadDocumentFileInfo]({ commit, dispatch, rootState, state }, { tempFileId, index }) {
		commit(mutationTypes.SET_IS_SIGNED_DOCUMENT_FILE_INFO_UPLOADING, true);
		try {
			const { id } = rootState.route.params;
			const documentItem = state.documentFileInfoItems[index];
			
			let documentResponse: ApiDocumentResponse = ApiDocumentResponseHelper.getEmpty();
			
			switch (documentItem.type) {
				case BankAccountApplicationDocumentTypeEnum.INFORMATIONAL_LETTER:
					documentResponse = await barController.setInformationalLetterDocumentFile(id, tempFileId);
					break;
				
				case BankAccountApplicationDocumentTypeEnum.PAYMENT_ACCEPT_AGREEMENT:
					documentResponse = await barController.setPaymentAcceptAgreementDocumentFile(id, tempFileId);
					break;
				
				case BankAccountApplicationDocumentTypeEnum.DIRECT_WITHDRAWAL_AGREEMENT:
					documentResponse = await barController.setDirectWithdrawalAgreementDocumentFile(id, tempFileId);
					break;
				
				case BankAccountApplicationDocumentTypeEnum.ACCOUNT_CORRECTION_AGREEMENT:
					documentResponse = await barController.setAccountCorrectionAgreementDocumentFile(id, tempFileId);
					break;
			}
			
			const { storedFileId, electronicStatus, paperStatus, explanation } = documentResponse;
			
			commit(mutationTypes.SET_DOCUMENT_FILE_INFO_ITEMS_ITEM_ELECTRONIC_STATUS, { index, value: electronicStatus });
			commit(mutationTypes.SET_DOCUMENT_FILE_INFO_ITEMS_ITEM_PAPER_STATUS, { index, value: paperStatus });
			commit(mutationTypes.SET_DOCUMENT_FILE_INFO_ITEMS_ITEM_STORED_FILE_ID, { index, value: storedFileId });
			commit(mutationTypes.SET_DOCUMENT_FILE_INFO_ITEMS_ITEM_EXPLANATION, { index, value: explanation });
			
			await dispatch(actionTypes.getDocuments);
			
			commit(mutationTypes.DOCUMENT_UPLOADED);
			
			alertService.addInfo(AlertKeys.DOCUMENT_SUCCESS_UPLOADED);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_SIGNED_DOCUMENT_FILE_INFO_UPLOADING, false);
		}
	},
	async [actionTypes.downloadFile]({ state }, { storedFileId, docName }) {
		try {
			const [file, meta] = await Promise.all([
				barStorageController.getFile(storedFileId),
				barStorageController.getFileMeta(storedFileId)
			]);
			
			const extension = getFileExtension(meta.name);
			
			saveAs(file, docName + extension);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		}
	}
};

const mutations = <MutationTree<BankAccountApplicationDocumentsState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...formMixin.mutations,
	...snapshotMixin.mutations,
	[mutationTypes.SET_IS_DOCUMENTS_LOADING](state, value) {
		state.isDocumentsLoading = value;
	},
	[mutationTypes.SET_DOCUMENT_FILE_INFO_ITEMS](state, value) {
		state.documentFileInfoItems = value;
	},
	[mutationTypes.SET_DOCUMENT_FILE_INFO_ITEMS_ITEM_ELECTRONIC_STATUS](state, { index, value }) {
		state.documentFileInfoItems[index].electronicStatus = cloneDeep(value);
	},
	[mutationTypes.SET_DOCUMENT_FILE_INFO_ITEMS_ITEM_PAPER_STATUS](state, { index, value }) {
		state.documentFileInfoItems[index].paperStatus = cloneDeep(value);
	},
	[mutationTypes.SET_DOCUMENT_FILE_INFO_ITEMS_ITEM_STORED_FILE_ID](state, { index, value }) {
		state.documentFileInfoItems[index].storedFileId = cloneDeep(value);
	},
	[mutationTypes.SET_DOCUMENT_FILE_INFO_ITEMS_ITEM_EXPLANATION](state, { index, value }) {
		state.documentFileInfoItems[index].explanation = cloneDeep(value);
	},
	[mutationTypes.SET_DOCUMENT_FILE_INFO_ITEMS_ITEM_IS_STATUS_CHANGING](state, { index, value }) {
		state.documentFileInfoItems[index].isStatusChanging = cloneDeep(value);
	},
	[mutationTypes.SET_DOCUMENT_FILE_INFO_ITEMS_ITEM_IS_COMMENT_CHANGING](state, { index, value }) {
		state.documentFileInfoItems[index].isCommentChanging = cloneDeep(value);
	},
	[mutationTypes.SET_DOCUMENT_FILE_INFO_ITEMS_ITEM_EXPLANATION](state, { index, value }) {
		state.documentFileInfoItems[index].explanation = value;
	},
	[mutationTypes.SET_DOCUMENT_FILE_INFO_ITEMS_ITEM_ELECTRONIC_STATUS](state, { index, value }) {
		state.documentFileInfoItems[index].electronicStatus = value;
	},
	[mutationTypes.SET_DOCUMENT_FILE_INFO_ITEMS_ITEM_PAPER_STATUS](state, { index, value }) {
		state.documentFileInfoItems[index].paperStatus = value;
	},
	[mutationTypes.RESET_DOCUMENT_FILE_META](state) {
		state.documentFileMeta = new FileMeta();
	},
	[mutationTypes.SET_DOCUMENT_FILE_META](state, value) {
		state.documentFileMeta = value;
	},
	[mutationTypes.SET_DOCUMENT_FILE_META_IS_LOADING](state, value) {
		state.documentFileMeta.isLoading = value;
	},
	[mutationTypes.SET_IS_SIGNED_DOCUMENT_FILE_INFO_UPLOADING](state, value) {
		state.isSignedDocumentFileInfoUploading = value;
	},
	[mutationTypes.SET_IS_SIGNED](state, value) {
		state.isSigned = value;
	},
	[mutationTypes.DOCUMENT_UPLOADED]() {
	},
	[mutationTypes.DOCUMENT_STATUS_UPDATED]() {
	}
};

export {
	namespace, state, getters, actions, mutations
};

const bankAccountApplicationDocumentsModule = {
	namespace, state, getters, actions, mutations, namespaced: true
};

export default bankAccountApplicationDocumentsModule;
