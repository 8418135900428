<template>
	<frp-card v-if="isInitialized" :title="$t('titles.legalDepartmentRequest')" class="pb-4">
		<template #content>
			<v-container fluid class="pt-0 pb-2 px-0">
				<v-row class="px-4">
					<template v-if="can(Permissions.BAR_APPLICATION_LEGAL_DEPARTMENT_REQUEST_EDIT_READ) && editableItem.isLegalDepartmentRequestSent && legalDepartmentInfo.legalDepartmentStaffEmployeeId && isResponsibleLegalDepartmentStaffEmployeeAppointed">
						<v-col class="px-2">
							<bar-bank-account-application-legal-department-info @responsible-user-dialog:open="handleOpenResponsibleUserDialog"
																				:is-edit-info-access-allowed="isLegalDepartmentRequestEditInfoAccessAllowed">
							</bar-bank-account-application-legal-department-info>
							<bar-bank-account-application-legal-department-documents :is-edit-documents-access-allowed="isLegalDepartmentRequestEditInfoAccessAllowed"/>
						</v-col>
					</template>
					
					<template v-else>
						<v-col cols="auto" align-self="center">
							<span class="text-body-2 primary--text">{{ $t("content.atypicalContractCoordination") }}</span>
						</v-col>
						<v-spacer></v-spacer>
						<v-col cols="auto" justify="end" class="py-0">
							<template v-if="!editableItem.isLegalDepartmentRequestSent && !legalDepartmentInfo.legalDepartmentStaffEmployeeId">
								<frp-btn elevation="0"
										 color="blue"
										 :loading="isLegalDepartmentRequestLoading"
										 :disabled="!buttonStatus(BankAccountApplicationButtonTypeEnum.SEND_REQUEST_TO_LD_BUTTON) || isLegalDepartmentRequestSentAccessDenied"
										 dark
										 @click="sendLegalDepartmentRequest"
										 download>
									{{ $t("buttons.sendLegalDepartmentRequest") }}
								</frp-btn>
							</template>
							
							<template v-else>
								<frp-btn elevation="0"
										 color="blue"
										 :disabled="!buttonStatus(BankAccountApplicationButtonTypeEnum.LEGAL_DEPARTMENT_ASSIGN_RESPONSIBLE_BUTTON) || !isLegalDepartmentRequestEditInfoAccessAllowed"
										 dark
										 @click="isAppointmentResponsibleLegalDepartmentStaffEmployeeDialogOpened = true"
										 download>
									{{ isLegalDepartmentRequestEditInfoAccessAllowed ? $t("buttons.appoint") : $t("common.isLegalDepartmentRequestSent") }}
								</frp-btn>
							</template>
						</v-col>
					</template>
				</v-row>
			</v-container>
			
			<frp-dialog v-model="isAppointmentResponsibleLegalDepartmentStaffEmployeeDialogOpened"
						max-width="568"
						persistent
						:title="$t('dialogs.appointmentResponsiblePerson.title')"
						@dialog:close="handleCloseAppointmentResponsibleLegalDepartmentStaffEmployeeDialog">
				<template #content="{ onIntersect }">
					<v-form :ref="refs.form"
							v-model="formValid"
							v-intersect="onIntersect">
						<frp-autocomplete v-model="responsibleStaffEmployee"
										  :items="lawyerStaffEmployees"
										  :label="$t('fields.responsiblePerson.label')"
										  item-text="fullName"
										  item-value="id"
										  :disabled="isResponsibleLegalDepartmentStaffEmployeeAppointing"
										  color="blue"
										  class="bar-field"
										  hide-details
										  required
										  :placeholder="$t('fields.responsiblePerson.placeholder')">
						</frp-autocomplete>
					</v-form>
				</template>
				
				<template #footer>
					<frp-btn outlined
							 :disabled="isResponsibleLegalDepartmentStaffEmployeeAppointing"
							 @click="handleCloseAppointmentResponsibleLegalDepartmentStaffEmployeeDialog"
							 color="primary">
						{{ $t("buttons.cancel") }}
					</frp-btn>
					<frp-btn elevation="0"
							 :color="colors.blue.base"
							 :disabled="!isStateContainsUnsavedChanges() || !formValid"
							 :loading="isResponsibleLegalDepartmentStaffEmployeeAppointing"
							 @click="handleAppointResponsibleLegalDepartmentStaffEmployee">
						<span class="white--text">{{ $t("buttons.appoint") }}</span>
					</frp-btn>
				</template>
			</frp-dialog>
		</template>
	</frp-card>
	<bar-bank-account-application-legal-department-loader v-else/>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpCard from "@/components/cards/FrpCard.vue";
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import FrpAutocomplete from "@/components/fields/FrpAutocomplete.vue";
import BarCardContentLayout from "@/components/layouts/BarCardContentLayout.vue";
import authorizationMixin from "@/mixins/authorizationMixin";
import colorsMixin from "@/mixins/colorsMixin";
import formMixin from "@/mixins/formMixin";
import { RouteNames } from "@/router/bar/routes";
import { namespace } from "@/store/bar/modules/bankAccountApplication/modules/legalDepartment/types";
import { getterTypes, actionTypes, mutationTypes } from "@/store/bar/modules/bankAccountApplication/modules/legalDepartment/types";
import bankAccountApplicationTypes, {
	getterTypes as bankAccountApplicationGetterTypes
} from "@/store/bar/modules/bankAccountApplication/types";
import { BankAccountApplicationButtonTypeEnum } from "@/store/bar/types/BankAccountApplicationButtonTypeEnum";
import storeManager from "@/store/manager";
import stateSnapshotKeys from "@/store/shared/snapshot/keys";
import BarBankAccountApplicationLegalDepartmentDocuments
	from "@/views/bar/bankAccountApplication/sections/modules/legalDepartment/BarBankAccountApplicationLegalDepartmentDocuments.vue";
import BarBankAccountApplicationLegalDepartmentInfo
	from "@/views/bar/bankAccountApplication/sections/modules/legalDepartment/BarBankAccountApplicationLegalDepartmentInfo.vue";
import BarBankAccountApplicationLegalDepartmentLoader
	from "@/views/bar/bankAccountApplication/sections/modules/legalDepartment/BarBankAccountApplicationLegalDepartmentLoader.vue";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);
const bankAccountApplicationHelpers = createNamespacedHelpers(storeManager.bar.bankAccountApplication.namespace);

export default {
	mixins: [colorsMixin, formMixin, authorizationMixin],
	data() {
		return {
			namespace,
			RouteNames,
			isAppointmentResponsibleLegalDepartmentStaffEmployeeDialogOpened: false
		};
	},
	computed: {
		...bankAccountApplicationHelpers.mapState({
			editableItem: state => state.editableItem
		}),
		BankAccountApplicationButtonTypeEnum() {
			return BankAccountApplicationButtonTypeEnum;
		},
		...bankAccountApplicationHelpers.mapGetters({
			lawyerStaffEmployees: bankAccountApplicationGetterTypes.lawyerStaffEmployees,
			buttonStatus: bankAccountApplicationTypes.getterTypes.buttonUnlocked
		}),
		...mapState({
			isInitialized: state => state.isInitialized,
			isLegalDepartmentRequestLoading: state => state.isLegalDepartmentRequestLoading,
			isResponsibleLegalDepartmentStaffEmployeeAppointing: state => state.isResponsibleLegalDepartmentStaffEmployeeAppointing,
			isResponsibleLegalDepartmentStaffEmployeeAppointed: state => state.isResponsibleLegalDepartmentStaffEmployeeAppointed,
			legalDepartmentInfo: state => state.legalDepartmentInfo,
			editableResponsibleStaffEmployeeId: state => state.editableResponsibleStaffEmployeeId
		}),
		...mapGetters({
			isStateContainsUnsavedChanges: getterTypes.stateContainsUnsavedChanges
		}),
		isLegalDepartmentRequestSentAccessDenied() {
			return !this.can([this.Permissions.BAR_APPLICATION_LEGAL_DEPARTMENT_REQUEST_SEND_CREATE, this.Permissions.BAR_APPLICATION_LEGAL_DEPARTMENT_REQUEST_SEND_UPDATE]);
		},
		isLegalDepartmentRequestEditInfoAccessAllowed() {
			return this.can([this.Permissions.BAR_APPLICATION_LEGAL_DEPARTMENT_REQUEST_EDIT_CREATE, this.Permissions.BAR_APPLICATION_LEGAL_DEPARTMENT_REQUEST_EDIT_UPDATE]);
		},
		responsibleStaffEmployee: {
			get() {
				return this.editableResponsibleStaffEmployeeId;
			},
			set(value) {
				this.setEditableResponsibleStaffEmployeeId(value);
			}
		}
	},
	methods: {
		...mapMutations({
			resetEditableResponsibleStaffEmployeeId: mutationTypes.RESET_EDITABLE_RESPONSIBLE_STAFF_EMPLOYEE_ID,
			setEditableResponsibleStaffEmployeeId: mutationTypes.SET_EDITABLE_RESPONSIBLE_STAFF_EMPLOYEE_ID,
			setLegalDepartmentStaffEmployeeId: mutationTypes.SET_LEGAL_DEPARTMENT_INFO_LEGAL_DEPARTMENT_STAFF_EMPLOYEE_ID,
			setStateSnapshot: mutationTypes.SET_STATE_SNAPSHOT
		}),
		...mapActions({
			sendLegalDepartmentRequest: actionTypes.sendLegalDepartmentRequest,
			setResponsibleLegalDepartmentStaffEmployee: actionTypes.setResponsibleLegalDepartmentStaffEmployee,
			rollback: actionTypes.cancelChanges
		}),
		handleOpenResponsibleUserDialog() {
			this.setEditableResponsibleStaffEmployeeId(this.legalDepartmentInfo.legalDepartmentStaffEmployeeId);
			this.setStateSnapshot(stateSnapshotKeys.LAST_SAVED);
			this.isAppointmentResponsibleLegalDepartmentStaffEmployeeDialogOpened = true;
		},
		async handleAppointResponsibleLegalDepartmentStaffEmployee() {
			await this.setResponsibleLegalDepartmentStaffEmployee();
			this.handleCloseAppointmentResponsibleLegalDepartmentStaffEmployeeDialog();
		},
		handleCloseAppointmentResponsibleLegalDepartmentStaffEmployeeDialog() {
			this.resetEditableResponsibleStaffEmployeeId();
			this.$refs[this.refs.form].resetValidation();
			
			this.rollback();
			this.isAppointmentResponsibleLegalDepartmentStaffEmployeeDialogOpened = false;
		}
	},
	components: {
		BarBankAccountApplicationLegalDepartmentLoader,
		FrpAutocomplete,
		FrpDialog,
		BarBankAccountApplicationLegalDepartmentDocuments,
		BarBankAccountApplicationLegalDepartmentInfo,
		BarCardContentLayout,
		FrpBtn,
		FrpCard
	}
};
</script>

<style scoped>
</style>
