<template>
	<div v-if="loading" :style="`max-height: ${size}px; max-width: ${size}px; min-height: ${size}px; min-width: ${size}px`"
		 class="d-flex align-center justify-center">
		<v-progress-circular size="32" width="3" color="primary" indeterminate/>
	</div>
	
	<div v-else>
		<v-img v-if="dataUrl" :width="size" :height="size" class="rounded-circle" :src="dataUrl"/>
		<div v-else :style="`height: ${size}px; width: ${size}px; overflow: hidden`"
			 class="d-flex align-center justify-center rounded-circle grey lighten-5">
			<v-icon :size="+size * 0.88" color="white">mdi-account</v-icon>
		</div>
	</div>
</template>

<script>
import { HrStorageController } from "@/api/hr/storage";
import { ApplicationModeType } from "@/types/ApplicationModeType";
import { getApplicationMode } from "@/utils/configuration";
import { blobToDataURL } from "@/utils/file";
import FrpIcon from "Components/icon/FrpIcon";
import colorsMixin from "Mixins/colorsMixin";
import AbortService from "Services/abortService";

const abortService = new AbortService();

export default {
	mixins: [colorsMixin],
	props: {
		size: {
			type: [String, Number],
			default: 125
		},
		id: String
	},
	data() {
		return {
			dataUrl: "",
			loading: false,
			storageController: null
		};
	},
	methods: {
		async fetch() {
			this.loading = true;
			
			try {
				const file = await this.storageController.getFile(this.id);
				
				this.dataUrl = blobToDataURL(file);
			} catch (error) {
				console.error(error);
			} finally {
				this.loading = false;
			}
		},
		initializeController() {
			if(this.storageController)
				return;
			
			const mode = getApplicationMode();
			
			switch (mode) {
				case ApplicationModeType.HR:
					this.storageController = new HrStorageController(abortService);
					break;
				default:
					throw Error(`FrpAvatar не работает модуле ${mode}`);
			}
			
			abortService.initialize();
		}
	},
	watch: {
		id: {
			async handler(value) {
				this.initializeController();
				
				this.dataUrl = "";
				
				if(value)
					await this.fetch();
			},
			immediate: true
		}
	},
	components: {
		FrpIcon
	}
};
</script>

<style scoped>

</style>
