<template>
	<hr-content-layout container-class="pt-1 pt-lg-2 px-4 px-lg-4" bg-height="112">
		<v-card elevation="0" class="kpi-card px-6 py-6 mb-6">
			<div class="d-flex align-center mb-6" style="gap: 8px">
				<span style="font-size: 24px; font-weight: 600; line-height: 32px">{{ $t("titles.vacationPlanAdministration") }}</span>
				<v-spacer></v-spacer>
				
				<frp-btn @click="isImportEmployeeDialogOpened = true"
						 color="blue"
						 no-margin
						 dark
						 elevation="0">
					{{ $t("buttons.addEmployee") }}
				</frp-btn>
				
				<frp-btn @click="isImportEmployeesDialogOpened = true"
						 color="blue"
						 no-margin
						 dark
						 elevation="0">
					{{ $t("buttons.importEmployees") }}
				</frp-btn>
				
				<frp-btn :to="{ name: RouteNames.VACATION_PLANS_ADMINISTRATION_HOLIDAYS, params: { year: filterValues.year } }"
						 v-if="filterValues.year || !years.length"
						 color="blue"
						 no-margin
						 dark
						 elevation="0">
					{{ $t("buttons.holidaysAdministration") }}
				</frp-btn>
			</div>
			<div class="d-flex flex-wrap" style="gap: 16px">
				<bod-autocomplete class="kpi-field"
								  hide-details
								  :loading="isYearsLoading"
								  style="width: 110px"
								  :items="yearValues"
								  required
								  v-model="internalFilterValues.year"
								  color="blue"
								  :placeholder="$t('fields.kpiYear.placeholder')">
				</bod-autocomplete>
				
				<frp-tree-autocomplete item-text="name"
									   item-value="id"
									   class="kpi-field"
									   :readonly="isYearsLoading || !years.length"
									   @keydown.enter="applyFilter"
									   @firstclick="fetchDepartments"
									   multiple
									   :loading="isDepartmentsLoading"
									   style="width: 350px"
									   :items="departments"
									   v-model="internalFilterValues.departmentIds"
									   color="blue"
									   :placeholder="$t('fields.hrDepartment.placeholder')"
									   children-key="departments"
									   hide-details>
				</frp-tree-autocomplete>
				
				<bod-autocomplete item-text="fullName"
								  item-value="id"
								  class="kpi-field"
								  :readonly="isYearsLoading || !years.length"
								  @keydown.enter="applyFilter"
								  @firstclick="fetchEmployees"
								  multiple
								  :loading="isEmployeesLoading"
								  style="width: 350px"
								  :items="employees"
								  v-model="internalFilterValues.employeeIds"
								  color="blue"
								  :placeholder="$t('fields.hrFilterEmployee.placeholder')"
								  hide-details>
				</bod-autocomplete>
				
				<v-spacer></v-spacer>
				
				<frp-btn @click="resetFilter"
						 :disabled="isFilterEmpty"
						 color="primary"
						 outlined
						 elevation="0">
					{{ $t("buttons.reset") }}
				</frp-btn>
				
				<frp-btn @click="applyFilter"
						 :disabled="!isFilterChanged"
						 no-margin
						 color="blue"
						 dark
						 elevation="0">
					{{ $t("buttons.apply") }}
				</frp-btn>
			</div>
		</v-card>
		
		<v-card elevation="0"
				class="kpi-card pa-0" v-if="filterValues.year">
			<v-row>
				<v-col class="py-0">
					<v-data-table :headers="headers"
								  :loading-text="$t('tables.loading')"
								  :loading="isItemsLoading"
								  hide-default-footer
								  item-key="id"
								  :items="formattedItems"
								  :mobile-breakpoint="mobileBreakpoint"
								  :items-per-page="-1"
								  :options.sync="options"
								  must-sort
								  class="loan-table bod-table hr-table d-flex flex-column">
						<template #item.plannedVacationDays="{ item }">
							<span @click="handleOpenUpdateVacationDaysDialog(item)"
								  class="text-decoration-underline blue--text" :style="`cursor: pointer`">
								{{ item.plannedVacationDays }}
							</span>
						</template>
						
						<template #item.atApplication="{ item }">
							<v-simple-checkbox v-if="!savingCanCreateUnplannedVacationApplicationEmployeeIds.some(x => x === item.id)"
											   :value="item.canCreateUnplannedVacationApplication"
											   color="blue"
											   @input="updateCanCreateUnplannedVacationApplication({ employeeId: item.id, value: $event })">
							</v-simple-checkbox>
							<v-progress-circular v-else indeterminate width="2" size="20"></v-progress-circular>
						</template>
						
						<template #item.action="{ item }">
							<frp-btn @click="handleOpenDeleteEmployeeDialog(item)"
									 v-if="!item.hasVacationPlan"
									 elevation="0" icon style="height: 32px; width: 32px" :color="colors.black.lighten1">
								<frp-icon src="ico_kpi-delete"></frp-icon>
							</frp-btn>
						</template>
					</v-data-table>
				</v-col>
			</v-row>
		</v-card>
		
		<frp-bottom-space height="24"></frp-bottom-space>
		
		<hr-import-employee-dialog v-model="isImportEmployeeDialogOpened"/>
		<hr-import-employees-dialog v-model="isImportEmployeesDialogOpened" @success="isImportEmployeesResponseDialogOpened = true"/>
		<hr-import-employees-result-dialog v-model="isImportEmployeesResponseDialogOpened"/>
		<hr-delete-employee-dialog v-model="isDeleteEmployeeDialogOpened" :employee.sync="openedDialogEmployee"/>
		<hr-update-vacation-days-dialog v-model="isUpdateVacationDaysDialogOpened" :employee.sync="openedDialogEmployee"/>
	</hr-content-layout>
</template>

<script>
import FrpDateField from "@/components/fields/FrpDateField.vue";
import FrpTextarea from "@/components/fields/FrpTextarea.vue";
import formMixin from "@/mixins/formMixin";
import stateSnapshotKeys from "@/store/shared/snapshot/keys";
import { parseDateToTime } from "@/utils/dates";
import { dateFormat } from "@/utils/formats";
import { localeCompare } from "@/utils/string";
import HrDeleteEmployeeDialog from "@/views/hr/vacationPlanEmployees/dialogs/HrDeleteEmployeeDialog.vue";
import HrImportEmployeeDialog from "@/views/hr/vacationPlanEmployees/dialogs/HrImportEmployeeDialog.vue";
import HrImportEmployeesDialog from "@/views/hr/vacationPlanEmployees/dialogs/HrImportEmployeesDialog.vue";
import HrImportEmployeesResultDialog from "@/views/hr/vacationPlanEmployees/dialogs/HrImportEmployeesResultDialog.vue";
import HrUpdateVacationDaysDialog from "@/views/hr/vacationPlanEmployees/dialogs/HrUpdateVacationDaysDialog.vue";
import FrpLinkBtn from "Components/buttons/FrpLinkBtn";
import FrpRouterLinkBtn from "Components/buttons/FrpRouterLinkBtn";
import FrpPagination from "Components/common/FrpPagination";
import BodAutocomplete from "Components/fields/BodAutocomplete";
import FrpTreeAutocomplete from "Components/fields/FrpTreeAutocomplete";
import FrpIcon from "Components/icon/FrpIcon";
import BodContentLayout from "Components/layouts/BodContentLayout";
import FrpBottomSpace from "Components/layouts/FrpBottomSpace";
import HrContentLayout from "Components/layouts/HrContentLayout";
import { assign, isEqual, first } from "lodash";
import authorizationMixin from "Mixins/authorizationMixin";
import { RouteNames } from "Router/hr/routes";
import { namespace } from "Store/hr/modules/vacationPlanEmployees";
import { actionTypes, getterTypes, mutationTypes } from "Store/hr/modules/vacationPlanEmployees/types";
import VacationPlanEmployeesFilter from "Store/hr/modules/vacationPlanEmployees/types/vacationPlanEmployeesFilter";
import { createNamespacedHelpers } from "vuex";
import { listMixin } from "Mixins/listMixin";
import colorsMixin from "Mixins/colorsMixin";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import FrpAlert from "Components/alerts/FrpAlert";
import FrpAlerts from "Components/alerts/FrpAlerts";
import FrpBtn from "Components/buttons/FrpBtn";
import FrpCheckbox from "Components/fields/FrpCheckbox";
import FrpTextField from "Components/fields/FrpTextField";
import BodFooter from "Components/layouts/BodFooter";
import BodMain from "Components/layouts/BodMain";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [listMixin, formMixin, colorsMixin, storeModuleBasedPage, authorizationMixin],
	metaInfo() {
		return {
			title: this.$t("titles.vacationPlanAdministration")
		};
	},
	data() {
		return {
			RouteNames,
			namespace,
			isImportEmployeesDialogOpened: false,
			isImportEmployeesResponseDialogOpened: false,
			isImportEmployeeDialogOpened: false,
			isDeleteEmployeeDialogOpened: false,
			isUpdateVacationDaysDialogOpened: false,
			openedDialogEmployee: null,
			headers: [
				{
					text: this.$t("tables.department"),
					value: "department.name",
					class: "text-caption",
					width: "21%",
					sort: (a, b) => localeCompare(a, b)
				},
				{
					text: this.$t("tables.fullName"),
					value: "fullName",
					class: "text-caption",
					width: "14%",
					sort: (a, b) => localeCompare(a, b)
				},
				{
					text: this.$t("tables.personnelNumber"),
					value: "personnelNumber",
					class: "text-caption",
					width: "12%"
				},
				{
					text: this.$t("tables.hiredDate"),
					value: "employmentDate",
					class: "text-caption",
					width: "10%",
					sort: (a, b) => (parseDateToTime(a, dateFormat) || 0) - (parseDateToTime(b, dateFormat) || 0)
				},
				{
					text: this.$t("tables.remainingVacationDays"),
					value: "remainingVacationDays",
					class: "text-caption",
					width: "13%",
				},
				{
					text: this.$t("tables.plannedVacationDays"),
					value: "plannedVacationDays",
					class: "text-caption",
					width: "15%",
					sort: (a, b) => localeCompare(a, b)
				},
				{
					text: this.$t("tables.atApplication"),
					value: "atApplication",
					class: "text-caption",
					sortable: false,
					width: "20%"
				},
				{
					text: "",
					value: "action",
					class: "text-caption",
					sortable: false,
					width: "0%"
				}
			],
			internalFilterValues: {
				year: undefined,
				departmentIds: [],
				employeeIds: []
			}
		};
	},
	computed: {
		...mapState({
			initialized: state => state.isInitialized,
			isYearsLoading: state => state.isYearsLoading,
			isDepartmentsLoading: state => state.isDepartmentsLoading,
			isEmployeesLoading: state => state.isEmployeesLoading,
			years: state => state.years,
			departments: state => state.departments,
			employees: state => state.employees,
			savingCanCreateUnplannedVacationApplicationEmployeeIds: state => state.savingCanCreateUnplannedVacationApplicationEmployeeIds,
		}),
		...mapGetters({
			formattedItems: getterTypes.formattedItems,
			isNoData: getterTypes.isListingEmpty,
			yearValues: getterTypes.yearValues
		}),
		filter() {
			return {
				year: this.internalFilterValues.year,
				departmentIds: this.internalFilterValues.departmentIds,
				employeeIds: this.internalFilterValues.employeeIds
			};
		},
		isFilterChanged() {
			return !isEqual(Object.fromEntries(Object.entries(this.internalFilterValues).map(([k, v]) => [k, v === null ? [] : v])),
				assign({}, this.filterValues));
		},
		isFilterEmpty() {
			return isEqual(new VacationPlanEmployeesFilter(first(this.yearValues)), this.filterValues);
		}
	},
	methods: {
		...mapMutations({
			setFilterYear: mutationTypes.SET_FILTER_YEAR,
			setFilterDepartmentIds: mutationTypes.SET_FILTER_DEPARTMENT_IDS,
			setFilterEmployeeIds: mutationTypes.SET_FILTER_EMPLOYEE_IDS,
			resetFilter: mutationTypes.RESET_FILTER,
			setUpdateVacationDaysRequestVacationDays: mutationTypes.SET_UPDATE_VACATION_DAYS_REQUEST_VACATION_DAYS,
			setUpdateVacationDaysRequestComment: mutationTypes.SET_UPDATE_VACATION_DAYS_REQUEST_COMMENT,
			setStateSnapshot: mutationTypes.SET_STATE_SNAPSHOT
		}),
		...mapActions({
			fetchDepartments: actionTypes.fetchDepartments,
			fetchEmployees: actionTypes.fetchEmployees,
			updateCanCreateUnplannedVacationApplication: actionTypes.updateCanCreateUnplannedVacationApplication
		}),
		setInternalFilterValues() {
			Object.keys(this.internalFilterValues).forEach(key => this.internalFilterValues[key] =
				Array.isArray(this.filterValues[key]) ? [...this.filterValues[key]] : this.filterValues[key]);
		},
		applyFilter() {
			this.setFilterYear(this.internalFilterValues.year);
			this.setFilterDepartmentIds(this.internalFilterValues.departmentIds || []);
			this.setFilterEmployeeIds(this.internalFilterValues.employeeIds || []);
		},
		handleOpenDeleteEmployeeDialog(employee) {
			this.openedDialogEmployee = employee;
			this.isDeleteEmployeeDialogOpened = true;
		},
		handleOpenUpdateVacationDaysDialog(employee) {
			this.openedDialogEmployee = employee;
			this.setUpdateVacationDaysRequestVacationDays(employee.plannedVacationDays);
			this.setUpdateVacationDaysRequestComment(employee.plannedVacationDaysComment);
			this.setStateSnapshot(stateSnapshotKeys.LAST_SAVED);
			this.isUpdateVacationDaysDialogOpened = true;
		}
	},
	created() {
		this.initializeStore();
	},
	components: {
		HrImportEmployeesResultDialog,
		HrUpdateVacationDaysDialog,
		HrImportEmployeeDialog,
		HrImportEmployeesDialog,
		HrDeleteEmployeeDialog,
		FrpDateField,
		FrpTextarea,
		FrpTreeAutocomplete,
		FrpRouterLinkBtn,
		FrpLinkBtn,
		FrpBottomSpace,
		BodAutocomplete,
		FrpPagination,
		FrpIcon,
		FrpBtn,
		FrpCheckbox,
		FrpTextField,
		BodMain,
		FrpAlerts,
		FrpAlert,
		BodFooter,
		BodContentLayout,
		HrContentLayout
	}
};
</script>
