<template>
	<frp-dialog :value="value"
				:title="$t('dialogs.futureAccountUsage.title')"
				max-width="750px"
				scrollable
				max-height="500px"
				persistent
				@scrolled-to-end="isScrolledToEnd = $event"
				@update:value="$emit('update:value', $event)">
		<template #content="{ onIntersect }">
			<v-row no-gutters style="border-bottom: 1px solid black" class="pb-2 mb-2">
				<v-col>{{ $t("tables.account") }}</v-col>
				<v-col style="margin-left: 140px">{{ $t("tables.selectNextAction") }}</v-col>
			</v-row>
			<template v-for="accountUsage in accountUsages">
				<template v-if="accountUsage.items.length">
					<v-row no-gutters class="mt-5 mb-2">
						<v-col>{{ getBankName(accountUsage.bankId) }}</v-col>
					</v-row>
					<template v-for="item in accountUsage.items">
						<v-row no-gutters
							   align="center"
							   class="pb-2">
							<v-col>№{{ item.accountNumber }}, {{ getCurrencyName(item.currencyId) }}</v-col>
							<frp-autocomplete v-model="item.usageType"
											  :items="getOptions(item.usageType)"
											  item-text="title"
											  item-value="code"
											  color="blue"
											  class="bar-field"
											  style="width: 280px"
											  hide-details
											  :placeholder="$t('fields.bankAccountType.label')">
							</frp-autocomplete>
						</v-row>
					</template>
				</template>
			</template>
		</template>
		
		<template #footer>
			<frp-btn elevation="0"
					 color="blue"
					 dark
					 :disabled="!isScrolledToEnd"
					 @click="handleClick">
				{{ $t("buttons.ok") }}
			</frp-btn>
		</template>
	</frp-dialog>
</template>

<script>
import { AccountUsageTypeEnum } from "@/api/bar/types/AccountUsageTypeEnum";
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpConfirmDialog from "@/components/dialogs/FrpConfirmDialog.vue";
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import FrpAutocomplete from "@/components/fields/FrpAutocomplete.vue";
import BarContentLayout from "@/components/layouts/BarContentLayout.vue";
import FrpTextBodyTwo from "@/components/typography/FrpTextBodyTwo.vue";
import colorsMixin from "@/mixins/colorsMixin";
import { RouteNames } from "@/router/bar/routes";
import { namespace } from "@/store/bar/modules/bankAccountApplication";
import { actionTypes, getterTypes } from "@/store/bar/modules/bankAccountApplication/types";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin],
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		dialogName: String,
		value: Boolean
	},
	data() {
		return {
			namespace,
			RouteNames,
			AccountUsageTypeEnum,
			isSelectNextDialogOpened: false,
			isAccountTypeChangeNoticeDialogOpened: false,
			isFutureAccountUsageDialogOpened: false,
			account: null,
			description1: {},
			description2: {},
			isScrolledToEnd: false
		};
	},
	computed: {
		...mapState({
			isInitialized: state => state.isInitialized,
			isRecordUniqueCheckInProgress: state => state.form.isRecordUniqueCheckInProgress,
			isBankAccountApplicationSaving: state => state.isBankAccountApplicationSaving,
			isBankAccountApplicationBankFormValid: state => state.isBankAccountApplicationBankFormValid,
			isBankAccountApplicationAccountsFormValid: state => state.isBankAccountApplicationAccountsFormValid,
			accountUsages: state => state.accountUsages,
			editableItem: state => state.editableItem,
			banks: state => state.banks,
			applicationBankAccountUsageTypes: state => state.applicationBankAccountUsageTypes,
			currencies: state => state.currencies
		}),
		...mapGetters({
			getBankName: getterTypes.getBankName
			
		}),
		accountUsages() {
			return this.editableItem.accountUsages.map(x => {
				return {
					...x,
					items: x.items.filter(item => item.accountId || item.usageType !== AccountUsageTypeEnum.MakeSecondary)
				};
			});
		}
	},
	methods: {
		...mapMutations({}),
		...mapActions({
			initializeStore: actionTypes.initialize,
			createBankAccountApplication: actionTypes.createBankAccountApplication,
			fetchAccountUsages: actionTypes.fetchAccountUsages
		}),
		getOptions(type) {
			switch (type) {
				case AccountUsageTypeEnum.MakeSecondary:
					return this.applicationBankAccountUsageTypes.filter(x => x.code !== AccountUsageTypeEnum.WillBeUsed);
				case AccountUsageTypeEnum.WillBeUsed:
					return this.applicationBankAccountUsageTypes.filter(x => x.code !== AccountUsageTypeEnum.MakeSecondary);
			}
		},
		getCurrencyName(id) {
			return this.currencies.find(x => x.id === id)?.russianName;
		},
		handleClick() {
			this.$emit("click");
			this.$emit("update:value", false);
		}
	},
	components: { FrpAutocomplete, FrpDialog, FrpTextBodyTwo, FrpConfirmDialog, FrpBtn, BarContentLayout }
};
</script>
