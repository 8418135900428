import {
	namespace,
	actionTypes,
	mutationTypes,
	getterTypes
} from "@/store/hr/modules/employee/modules/feedback/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import AbortService from "@/services/abortService";
import { HrController } from "@/api/hr";
import router from "@/router/hr";
import { actionTypes as rootActionTypes } from "@/store/hr/types";
import AccessForbiddenException from "@/exceptions/accessForbiddenException";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import EmployeeFeedbackState from "@/store/hr/modules/employee/modules/feedback/types/employeeFeedbackState";

const abortService = new AbortService();
const hrController = new HrController(abortService);

class DefaultStateBuilder {
	constructor() {
	}
	
	build() {
		return new EmployeeFeedbackState(
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<EmployeeFeedbackState, any>>{
};

const actions = <ActionTree<EmployeeFeedbackState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit }) {
		await dispatch(actionTypes.initializeBase);
		
		await dispatch(actionTypes.fetch);
		
		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.fetch]({ dispatch, commit }) {
		commit(mutationTypes.SET_IS_LOADING, true);
		
		try {
			const data = await hrController.getEmployeeFeedback(router.currentRoute.params.id);
			
			commit(mutationTypes.SET_FEEDBACK, data);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_LOADING, false);
		}
	}
};

const mutations = <MutationTree<EmployeeFeedbackState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	[mutationTypes.SET_IS_LOADING](state, value) {
		state.isLoading = value;
	},
	[mutationTypes.SET_FEEDBACK](state, value) {
		state.feedback = value;
	}
};

export {
	namespace, state, getters, actions, mutations
};

const employeeFeedbackModule = {
	namespace, state, getters, actions, mutations, namespaced: true
};

export default employeeFeedbackModule;
