import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import RouteState from "@/store/shared/route/types/routeState";
import IPageState from "@/store/shared/base/types/pageState";
import FormState from "@/store/shared/form/models/formState";
import VacationPlanEmployeesFilter from "@/store/hr/modules/vacationPlanEmployees/types/vacationPlanEmployeesFilter";
import { ApiHrEmployeesItem } from "@/api/hr/types/apiHrEmployeesItem";
import { ApiHrDepartment } from "@/api/hr/types/apiHrDepartment";
import {
	HrUpdateEmployeeVacationDaysRequest,
	HrUpdateEmployeeVacationDaysRequestService
} from "@/types/hr/vacation/hrUpdateEmployeeVacationDays";
import { HrImportEmployeesResponse, HrImportEmployeesResponseService } from "@/types/hr/vacation/hrImportEmployeesResponse";
import { HrImportEmployeesRequest, HrImportEmployeesRequestService } from "@/types/hr/vacation/hrImportEmployeesRequest";
import { HrAdministrationEmployeesItem } from "@/types/hr/vacation/hrAdministrationEmployeesItem";
import { ApiHrVacationYear } from "@/api/hr/types/vacation/apiHrVacationYear";

export default class VacationPlanEmployeesState implements IPageState {
	constructor(
		public listing: ListingModel<HrAdministrationEmployeesItem>,
		public sorting: SortingModel<string>,
		public paging: PagingModel,
		public search: SearchModel,
		public filter: VacationPlanEmployeesFilter,
		public route: RouteState,
		public form: FormState,
		public snapshot: object,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isYearsLoading: boolean = false,
		public isDepartmentsLoading: boolean = false,
		public isEmployeesLoading: boolean = false,
		public years: ApiHrVacationYear[] = [],
		public departments: ApiHrDepartment[] = [],
		public employees: ApiHrEmployeesItem[] = [],
		public updateVacationDaysRequest: HrUpdateEmployeeVacationDaysRequest = HrUpdateEmployeeVacationDaysRequestService.getEmpty(),
		public importEmployeesRequest: HrImportEmployeesRequest = HrImportEmployeesRequestService.getEmpty(),
		public importEmployeesResponse: HrImportEmployeesResponse = HrImportEmployeesResponseService.getEmpty(),
		public savingCanCreateUnplannedVacationApplicationEmployeeIds: string[] = []
	)
	{
	}
}
