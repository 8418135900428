<template>
	<v-row class="flex-column">
		<v-col class="px-0" v-if="sortedLegalDepartmentDocumentFileInfoItems.length">
			<div class="bar-legal-department-document-file-info-items-wrapper d-flex flex-column">
				<div v-for="(item, i) in sortedLegalDepartmentDocumentFileInfoItems"
					 :key="item.orderNumber"
					 class="bar-legal-department-document-file-info-item d-flex align-center justify-space-between">
					<div class="bar-legal-department-document-file-info-item__info d-flex align-center text-body-2 primary--text pl-4">
						<frp-text-btn :wrap="true"
									  class="pa-0 bar-text-btn"
									  :text="getLegalDepartmentDocumentFullInfo(item)"
									  @click="downloadDocumentFile(item.storedFileId, item.documentName)"
									  color="primary">
						</frp-text-btn>
					</div>
					<frp-btn no-margin
							 small
							 height="32"
							 :color="colors.blue.base"
							 class="mr-2"
							 :disabled="!can(Permissions.BAR_ADMINISTRATOR_DELETE) || !isCurrentUserLegalDepartmentResponsiblePerson"
							 :loading="item.isDocumentDeleting"
							 @click="deleteLegalDepartmentDocument(item.storedFileId)"
							 icon>
						<frp-icon src="ico_delete" :color="colors.primary.base"></frp-icon>
					</frp-btn>
				</div>
			</div>
		</v-col>
		<v-col class="pb-1 px-4" :class="{'pb-4': legalDepartmentDocumentFile}">
			<bar-dropzone max-size="25"
						  @file:add="handleUploadDocumentFile($event, STORAGE_BANK_ACCOUNT_APPLICATION_LEGAL_DEPARTMENT_DOCUMENT_NAMESPACE)"
						  :data-cy="STORAGE_BANK_ACCOUNT_APPLICATION_LEGAL_DEPARTMENT_DOCUMENT_NAMESPACE"
						  :disabled="!buttonStatus(BankAccountApplicationButtonTypeEnum.LEGAL_DEPARTMENT_UPLOAD_DOCUMENT_BUTTON) || !isCurrentUserLegalDepartmentResponsiblePerson || legalDepartmentInfo.isAccepted"
						  :formats="['pdf']"
						  :file="legalDepartmentDocumentFile"
						  :is-uploading="legalDepartmentDocumentFileMetaIsLoading"
						  @file:delete="handleLegalDepartmentDocumentFileDelete"
						  @format:is-valid="isValidFileFormat = $event"
						  :type="STORAGE_BANK_ACCOUNT_APPLICATION_LEGAL_DEPARTMENT_DOCUMENT_NAMESPACE">
				<template #file-action>
					<frp-btn elevation="0"
							 class="mr-1"
							 :loading="legalDepartmentDocumentFileMetaIsLoading || isLegalDepartmentDocumentFileInfoUploading"
							 :disabled="!isValidFileFormat"
							 :color="colors.blue.base"
							 @click="handleUploadLegalDepartmentDocumentFileInfo"
							 dark>
						{{ $t("buttons.upload") }}
					</frp-btn>
				</template>
			</bar-dropzone>
		</v-col>
	</v-row>
</template>

<script>
import { BarStorageController } from "@/api/barStorage";
import ApiFile from "@/api/types/storage/apiFile";
import ApiFileMeta from "@/api/types/storage/apiFileMeta";
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpTextBtn from "@/components/buttons/FrpTextBtn.vue";
import FrpCard from "@/components/cards/FrpCard.vue";
import BarDropzone from "@/components/dropzone/BarDropzone.vue";
import FrpIcon from "@/components/icon/FrpIcon.vue";
import BarCardContentLayout from "@/components/layouts/BarCardContentLayout.vue";
import FrpTextBodyTwo from "@/components/typography/FrpTextBodyTwo.vue";
import { STORAGE_BANK_ACCOUNT_APPLICATION_LEGAL_DEPARTMENT_DOCUMENT_NAMESPACE } from "@/constants/storage";
import authorizationMixin from "@/mixins/authorizationMixin";
import colorsMixin from "@/mixins/colorsMixin";
import { RouteNames } from "@/router/bar/routes";
import AbortService from "@/services/abortService";
import { namespace } from "@/store/bar/modules/bankAccountApplication/modules/legalDepartment/types";
import { getterTypes, mutationTypes, actionTypes } from "@/store/bar/modules/bankAccountApplication/modules/legalDepartment/types";
import bankAccountApplicationTypes from "@/store/bar/modules/bankAccountApplication/types";
import { getterTypes as barUserGetterTypes } from "@/store/bar/modules/user/types";
import { BankAccountApplicationButtonTypeEnum } from "@/store/bar/types/BankAccountApplicationButtonTypeEnum";
import storeManager from "@/store/manager";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import storageMapper from "@/store/shared/storage/mapper";
import FileMeta from "@/store/shared/storage/types/fileMeta";
import { formatDate } from "@/utils/dates";
import { dateTimeFormat } from "@/utils/formats";
import { saveAs } from "file-saver";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);
const bankAccountApplicationHelpers = createNamespacedHelpers(storeManager.bar.bankAccountApplication.namespace);

const barUserHelpers = createNamespacedHelpers(storeManager.bar.barUser.namespace);

const abortService = new AbortService();
const barStorageController = new BarStorageController(abortService);

export default {
	mixins: [colorsMixin, authorizationMixin],
	props: {
		isEditInfoAccessAllowed: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			namespace,
			RouteNames,
			formatDate,
			dateTimeFormat,
			STORAGE_BANK_ACCOUNT_APPLICATION_LEGAL_DEPARTMENT_DOCUMENT_NAMESPACE,
			legalDepartmentDocumentFile: null,
			isValidFileFormat: false
		};
	},
	computed: {
		BankAccountApplicationButtonTypeEnum() {
			return BankAccountApplicationButtonTypeEnum;
		},
		...barUserHelpers.mapState({
		    user: state => state.user
	    }),
		...barUserHelpers.mapGetters({
			isBarUserAdministrator: barUserGetterTypes.isBarUserAdministrator
	    }),
		...bankAccountApplicationHelpers.mapState({
			editableItem: state => state.editableItem,
			staffEmployees: state => state.staffEmployees
		}),
		...bankAccountApplicationHelpers.mapGetters({
			buttonStatus: bankAccountApplicationTypes.getterTypes.buttonUnlocked
		}),
		...mapState({
			isInitialized: state => state.isInitialized,
			legalDepartmentDocumentFileMetaIsLoading: state => state.legalDepartmentDocumentFileMeta.isLoading,
			isLegalDepartmentDocumentFileInfoUploading: state => state.isLegalDepartmentDocumentFileInfoUploading,
			legalDepartmentDocumentFileMeta: state => state.legalDepartmentDocumentFileMeta,
			legalDepartmentInfo: state => state.legalDepartmentInfo
		}),
		...mapGetters({
			sortedLegalDepartmentDocumentFileInfoItems: getterTypes.sortedLegalDepartmentDocumentFileInfoItems
		}),
		isCurrentUserLegalDepartmentResponsibleEmployee() {
			return this.user.employeeId === this.legalDepartmentInfo.legalDepartmentStaffEmployeeId;
		},
		isCurrentUserLegalDepartmentResponsiblePerson() {
			return this.isBarUserAdministrator || this.isCurrentUserLegalDepartmentResponsibleEmployee;
		}
	},
	methods: {
		...mapMutations({
			resetLegalDepartmentDocumentFileMeta: mutationTypes.RESET_LEGAL_DEPARTMENT_DOCUMENT_FILE_META,
			setLegalDepartmentDocumentFileMeta: mutationTypes.SET_LEGAL_DEPARTMENT_DOCUMENT_FILE_META,
			setLegalDepartmentDocumentFileMetaIsLoading: mutationTypes.SET_LEGAL_DEPARTMENT_DOCUMENT_FILE_META_IS_LOADING
		}),
		...mapActions({
			uploadLegalDepartmentDocumentFileInfo: actionTypes.uploadLegalDepartmentDocumentFileInfo,
			deleteLegalDepartmentDocument: actionTypes.deleteLegalDepartmentDocument
		}),
		getLegalDepartmentDocumentFullInfo(item) {
			const date = formatDate(item?.createdAt, dateTimeFormat);
			const employeeName = this.staffEmployees.find(x => x.id === item?.createdBy)?.fullName;
			
			if(item?.documentName && date && employeeName)
				return `${item.orderNumber}. ${item.documentName} - ${date} (${employeeName})`;
		},
		async handleUploadLegalDepartmentDocumentFileInfo() {
			await this.uploadLegalDepartmentDocumentFileInfo();
			this.handleLegalDepartmentDocumentFileDelete();
		},
		async handleUploadDocumentFile(file, docType) {
			if(!file) {
				this.handleLegalDepartmentDocumentFileDelete();
				return;
			}
			
			this.legalDepartmentDocumentFile = file;
			
			this.setLegalDepartmentDocumentFileMetaIsLoading(true);
			try {
				const { name, type } = file;
				let meta = await barStorageController.createTemperFile(new ApiFile(file, name, docType, type, ""));
				
				this.setLegalDepartmentDocumentFileMeta(storageMapper.map(meta, ApiFileMeta, FileMeta));
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.setLegalDepartmentDocumentFileMetaIsLoading(false);
			}
		},
		handleLegalDepartmentDocumentFileDelete() {
			this.legalDepartmentDocumentFile = null;
			this.resetLegalDepartmentDocumentFileMeta();
		},
		async downloadDocumentFile(storedFileId, docName) {
			try {
				const file = await barStorageController.getFile(storedFileId);
				await saveAs(file, docName);
			} catch (error) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		}
	},
	created() {
		abortService.initialize();
	},
	watch: {
		legalDepartmentDocumentFile: {
			handler(value) {
				this.$emit("update:document-exist", !!value);
			},
			immediate: true
		}
	},
	components: { FrpTextBtn, FrpTextBodyTwo, FrpIcon, BarDropzone, BarCardContentLayout, FrpBtn, FrpCard }
};
</script>

<style lang="scss">
.bar-legal-department-document-file-info-items-wrapper {
	min-height: 64px !important;
	max-height: 192px !important;
	overflow-y: auto;
	overflow-x: hidden;

	&::-webkit-scrollbar {
		width: 4px !important;
	}

	&::-webkit-scrollbar-track {
		background-color: transparent !important;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--v-grey-lighten2) !important;
	}

}

.bar-legal-department-document-file-info-item {
	min-height: 64px !important;
	
	&__info {
		max-width: 90%;
	  
	  .bar-text-btn {
		max-width: 100%;
	  }
	}
}

.bar-legal-department-document-file-info-item:not(:last-child) {
	border-bottom: 1px solid var(--v-grey-lighten2);
}
</style>
