<template>
	<v-form v-if="isInitialized"
			:ref="refs.form"
			v-model="formValid"
			class="d-flex align-center pl-12">
		<frp-btn v-if="isDirectWithdrawalAgreement && directWithdrawalAgreementTemplateUrl"
				 elevation="0"
				 class="bar-btn"
				 fab
				 small
				 dark
				 :href="directWithdrawalAgreementTemplateUrl"
				 :color="colors.blue.base">
			Ш
		</frp-btn>
		<frp-btn elevation="0"
				 class="bar-btn mr-4"
				 style="height: 36px;"
				 :disabled="!buttonStatus(BankAccountApplicationButtonTypeEnum.UPLOAD_DOCUMENT_BUTTON) || isDocumentUploadAccessDenied || documentItem.isStatusChanging || isDisabledUploadButton || !isDocumentStatusAutocompleteValid"
				 dark
				 @click="isUploadDocumentDialogOpened = true"
				 :color="colors.blue.base">
			{{ $t("buttons.upload") }}
		</frp-btn>
		
		<!--		TODO: сделать смену на нулевой статус, как сделают метод на бэке-->
		<frp-autocomplete v-model="electronicStatus"
						  :items="selectableElectronicDocumentStatuses(!documentItem?.storedFileId)"
						  @update:field-is-valid="isDocumentStatusAutocompleteValid = $event"
						  allow-invalid-values
						  item-text="title"
						  item-value="code"
						  color="blue"
						  class="bar-field bar-field--text-body-2 mr-4"
						  style="width: 135px"
						  hide-details
						  :disabled="!buttonStatus(BankAccountApplicationButtonTypeEnum.ELECTRONIC_STATUS_BUTTON) || isEditDocumentStatusAccessDenied"
						  :loading="documentItem.isStatusChanging"
						  :placeholder="$t('fields.status.label')">
		</frp-autocomplete>
		<frp-autocomplete v-model="paperStatus"
						  :items="selectablePaperDocumentStatuses"
						  allow-invalid-values
						  item-text="title"
						  item-value="code"
						  color="blue"
						  class="bar-field bar-field--text-body-2"
						  style="width: 135px;"
						  hide-details
						  :disabled="!buttonStatus(BankAccountApplicationButtonTypeEnum.PAPER_STATUS_BUTTON) || isEditDocumentStatusAccessDenied"
						  :loading="documentItem.isStatusChanging"
						  :placeholder="$t('fields.status.label')">
		</frp-autocomplete>
		
		<frp-text-caption class="ml-3 text-wrap primary--text" style="width: 135px; word-break: break-word">
			<span class="d-flex align-center">{{documentItem?.explanation}}</span>
		</frp-text-caption>
		
		<frp-btn v-if="isBarUserAdministrator || isBarUserCuratorsDepartmentManager"
				 :disabled="!buttonStatus(BankAccountApplicationButtonTypeEnum.COMMENT_BUTTON)"
				 without-padding
				 height="28"
				 small
				 icon
				 @click="handleOpenCommentDialog"
				 class="ml-1"
				 color="blue"
				 dark>
			<frp-icon src="ico_edit" :color="colors.blue.base"></frp-icon>
		</frp-btn>
		
		<div v-else style="width: 28px; height: 28px;" class="ml-1"></div>
		
		<template
			v-if="documentItem?.explanation || isAcceptedAndHasAcceptor">
			<frp-btn without-padding
					 height="28"
					 small
					 icon
					 style="margin-left: 8px !important;"
					 @click="handleOpenCommentDialogInReadMode"
					 color="blue"
					 dark>
				<frp-icon src="ico_message" :color="colors.blue.base"></frp-icon>
			</frp-btn>
		</template>
		<template v-else>
			<div style="width: 28px; height: 28px;" class="ml-2"></div>
		</template>
		
		<frp-dialog persistent
					v-model="isCommentDialogOpened"
					max-width="568"
					:title="declineStatusExplanationMode === DeclineStatusExplanationDialogMode.EDIT ? $t('dialogs.declineStatusExplanation.title') : ''">
			<template #content="{ onIntersect }">
				<v-form v-if="declineStatusExplanationMode === DeclineStatusExplanationDialogMode.EDIT"
						:ref="refs.form"
						v-intersect="onIntersect"
						v-model="commentFormValid"
						class="pt-1">
					<frp-textarea v-model="declineExplanation"
								  :required="commentRequired"
								  :placeholder="$t('fields.declineExplanation.placeholder')">
					</frp-textarea>
				</v-form>
				
				<div v-else
					 v-intersect="onIntersect"
					 class="primary--text text-body-2">
					<div v-if="documentItem.explanation">
						<h2 class="mb-3">{{ $t("dialogs.declineStatusExplanation.subtitle") }}</h2>
						<p style="white-space: break-spaces;">{{ documentItem.explanation }}</p>
					</div>
					<div v-if="isAcceptedAndHasAcceptor">
						<h2 class="mb-3">{{ $t("dialogs.acceptanceDetails.subtitle") }}</h2>
						<frp-details-item :title="$t('details.acceptedEmployeeName')"
										  :value="acceptorDetails.acceptedEmployeeName"/>
						<frp-details-item :title="$t('details.acceptedDate')"
										  :value="acceptorDetails.acceptedDate"/>
					</div>
				</div>
			</template>
			
			<template #footer>
				<template v-if="declineStatusExplanationMode === DeclineStatusExplanationDialogMode.EDIT">
					<frp-btn outlined
							 :disabled="documentItem.isStatusChanging || documentItem.isCommentChanging"
							 @click="handleCloseCommentDialog"
							 color="primary">
						{{ $t("buttons.cancel") }}
					</frp-btn>
					<frp-btn elevation="0"
							 color="blue"
							 dark
							 :loading="documentItem.isStatusChanging || documentItem.isCommentChanging"
							 :disabled="!commentFormValid || !stateContainsUnsavedChanges"
							 @click="handleSaveComment">
						<span class="white--text">{{ $t("buttons.save") }}</span>
					</frp-btn>
				</template>
				
				<template v-else>
					<frp-btn outlined
							 @click="isCommentDialogOpened = false"
							 color="primary">
						{{ $t("buttons.close") }}
					</frp-btn>
				</template>
			</template>
		</frp-dialog>
		
		<frp-dialog v-model="isUploadDocumentDialogOpened"
					:title="$t('dialogs.uploadDocument.title')"
					max-width="656"
					persistent
					@dialog:close="handleCloseUploadDocumentDialog">
			<template #content="{ onIntersect }">
				<v-container>
					<v-row>
						<v-col class="px-0">
							<bar-dropzone max-size="25"
										  @file:add="handleUploadDocumentFile($event, STORAGE_BANK_ACCOUNT_APPLICATION_DOCUMENT_NAMESPACE)"
										  :data-cy="`${STORAGE_BANK_ACCOUNT_APPLICATION_DOCUMENT_NAMESPACE}__index:${documentIndex}`"
										  :formats="dropzone.formats"
										  :title="dropzone?.title"
										  :subtitle="dropzone?.subtitle"
										  :file="documentFile"
										  :is-uploading="documentFileMetaIsLoading"
										  @file:delete="handleDocumentFileDelete"
										  @format:is-valid="isValidFileFormat = $event"
										  :type="STORAGE_BANK_ACCOUNT_APPLICATION_DOCUMENT_NAMESPACE">
								<template #file-action>
									<frp-digital-signature-dialog
										:data-url="dataUrl"
										v-model="isSignatureDialogOpened"
										:file="documentFile"
										:submit-btn="$t('buttons.choose')"
										@signed="handleUploadSignedDigitalSignatureFile($event, STORAGE_BANK_ACCOUNT_APPLICATION_DOCUMENT_NAMESPACE)"
										:meta="documentFileMeta"
										color="blue"
										button-elevation="0"
										pdf>
										<template #activator="{ on, attrs }">
											<frp-btn elevation="0"
													 v-on="on"
													 class="mr-1"
													 v-bind="attrs"
													 :loading="documentFileMetaIsLoading || isSignedDocumentFileInfoUploading"
													 :disabled="!isValidFileFormat"
													 :color="colors.blue.base"
													 dark>
												{{ $t("buttons.uploadWithSignature") }}
											</frp-btn>
										</template>
									</frp-digital-signature-dialog>
								</template>
							</bar-dropzone>
						</v-col>
					</v-row>
				</v-container>
			</template>
			
			<template #footer>
				<frp-btn @click="handleCloseUploadDocumentDialog"
						 outlined
						 :disabled="documentFileMetaIsLoading || isSignedDocumentFileInfoUploading"
						 elevation="0"
						 color="primary">
					{{ $t("buttons.cancel") }}
				</frp-btn>
			</template>
		</frp-dialog>
	</v-form>
</template>

<script>
import { BarStorageController } from "@/api/barStorage";
import ApiFile from "@/api/types/storage/apiFile";
import ApiFileMeta from "@/api/types/storage/apiFileMeta";
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpCard from "@/components/cards/FrpCard.vue";
import BarDetailsGroup from "@/components/details/BarDetailsGroup.vue";
import BarDetailsItem from "@/components/details/BarDetailsItem.vue";
import FrpDetailsItem from "@/components/details/FrpDetailsItem.vue";
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import FrpDigitalSignatureDialog from "@/components/digitalSignature/FrpDigitalSignatureDialog.vue";
import BarDropzone from "@/components/dropzone/BarDropzone.vue";
import LoanDropzone from "@/components/dropzone/LoanDropzone.vue";
import FrpAutocomplete from "@/components/fields/FrpAutocomplete.vue";
import FrpDateField from "@/components/fields/FrpDateField.vue";
import FrpTextarea from "@/components/fields/FrpTextarea.vue";
import FrpTextField from "@/components/fields/FrpTextField.vue";
import FrpIcon from "@/components/icon/FrpIcon.vue";
import FrpTextBodyTwo from "@/components/typography/FrpTextBodyTwo.vue";
import FrpTextCaption from "@/components/typography/FrpTextCaption.vue";
import { Permissions } from "@/constants/permissions";
import { STORAGE_BANK_ACCOUNT_APPLICATION_DOCUMENT_NAMESPACE } from "@/constants/storage";
import authorizationMixin from "@/mixins/authorizationMixin";
import colorsMixin from "@/mixins/colorsMixin";
import formMixin from "@/mixins/formMixin";
import { RouteNames } from "@/router/bar/routes";
import AbortService from "@/services/abortService";
import { namespace } from "@/store/bar/modules/bankAccountApplication/modules/documents";
import { actionTypes, getterTypes, mutationTypes } from "@/store/bar/modules/bankAccountApplication/modules/documents/types";
import bankAccountApplicationTypes from "@/store/bar/modules/bankAccountApplication/types";
import { getterTypes as barUserModuleGetterTypes } from "@/store/bar/modules/user/types";
import { BankAccountApplicationButtonTypeEnum } from "@/store/bar/types/BankAccountApplicationButtonTypeEnum";
import { BankAccountApplicationDocumentTypeEnum } from "@/store/bar/types/BankAccountApplicationDocumentTypeEnum";
import { BankAccountApplicationStatusTypeEnum } from "@/store/bar/types/BankAccountApplicationStatusTypeEnum";
import { DocumentResponse } from "@/store/bar/types/documentResponse";
import { ElectronicDocumentStatusTypeEnum } from "@/store/bar/types/ElectronicDocumentStatusTypeEnum";
import storeManager from "@/store/manager";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import stateSnapshotKeys from "@/store/shared/snapshot/keys";
import storageMapper from "@/store/shared/storage/mapper";
import FileMeta from "@/store/shared/storage/types/fileMeta";
import { formatDate } from "@/utils/dates";
import { dateTimeFormat } from "@/utils/formats";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);
const bankAccountApplicationHelpers = createNamespacedHelpers(storeManager.bar.bankAccountApplication.namespace);
const barUserModuleHelpers = createNamespacedHelpers(storeManager.bar.barUser.namespace);

const abortService = new AbortService();
const barStorageController = new BarStorageController(abortService);

const DeclineStatusExplanationDialogMode = {
	READ: "READ",
	EDIT: "EDIT"
};

export default {
	mixins: [colorsMixin, formMixin, authorizationMixin],
	props: {
		documentItem: DocumentResponse,
		documentIndex: {
			type: [String, Number]
		}
	},
	data() {
		return {
			namespace,
			RouteNames,
			DocumentStatusTypeEnum: ElectronicDocumentStatusTypeEnum,
			BankAccountApplicationDocumentTypeEnum,
			STORAGE_BANK_ACCOUNT_APPLICATION_DOCUMENT_NAMESPACE,
			DeclineStatusExplanationDialogMode,
			isCommentDialogOpened: false,
			declineStatusExplanationMode: DeclineStatusExplanationDialogMode.EDIT,
			documentFile: null,
			dataUrl: "",
			isValidFileFormat: false,
			isUploadDocumentDialogOpened: false,
			isSignatureDialogOpened: false,
			isDocumentStatusAutocompleteValid: true,
			handleSaveComment: null,
			commentRequired: false,
			commentFormValid: false
		};
	},
	computed: {
		ElectronicDocumentStatusTypeEnum() {
			return ElectronicDocumentStatusTypeEnum;
		},
		BankAccountApplicationButtonTypeEnum() {
			return BankAccountApplicationButtonTypeEnum;
		},
		...bankAccountApplicationHelpers.mapGetters({
			buttonStatus: bankAccountApplicationTypes.getterTypes.buttonUnlocked
		}),
		...barUserModuleHelpers.mapGetters({
			isBarUserAdministrator: barUserModuleGetterTypes.isBarUserAdministrator,
			isBarUserCuratorsDepartmentManager: barUserModuleGetterTypes.isBarUserCuratorsDepartmentManager
		}),
		...mapState({
			isInitialized: state => state.isInitialized,
			documentFileInfoItems: state => state.documentFileInfoItems,
			certificateInfo: state => state.certificateInfo,
			isSigned: state => state.isSigned,
			documentFileMeta: state => state.documentFileMeta,
			documentFileMetaIsLoading: state => state.documentFileMeta.isLoading,
			isSignedDocumentFileInfoUploading: state => state.isSignedDocumentFileInfoUploading
		}),
		...bankAccountApplicationHelpers.mapState({
			electronicDocumentStatuses: state => state.electronicDocumentStatuses,
			editableItem: state => state.editableItem,
			banks: state => state.banks,
			staffEmployees: state => state.staffEmployees
		}),
		...mapGetters({
			selectableElectronicDocumentStatuses: getterTypes.selectableElectronicDocumentStatuses,
			selectablePaperDocumentStatuses: getterTypes.selectablePaperDocumentStatuses
		}),
		directWithdrawalAgreementTemplateUrl() {
			return this.banks.find(({ id }) => id === this.editableItem.bankId)?.directWithdrawalAgreementTemplateUrl ?? "";
		},
		isDocumentUploadAccessDenied() {
			return !this.can([Permissions.BAR_APPLICATION_UPLOAD_DOCUMENTS_CREATE, Permissions.BAR_APPLICATION_UPLOAD_DOCUMENTS_UPDATE]);
		},
		isEditDocumentStatusAccessDenied() {
			return !this.can(Permissions.BAR_APPLICATION_EDIT_DOCUMENTS_CREATE) ||
				!this.can(Permissions.BAR_APPLICATION_EDIT_DOCUMENTS_UPDATE);
		},
		isDisabledUploadButton() {
			return this.documentItem.electronicStatus === ElectronicDocumentStatusTypeEnum.NOT_REQUIRED ||
				this.documentItem.electronicStatus === ElectronicDocumentStatusTypeEnum.ACCEPTED;
		},
		isDirectWithdrawalAgreement() {
			return this.documentItem.type === BankAccountApplicationDocumentTypeEnum.DIRECT_WITHDRAWAL_AGREEMENT;
		},
		isAcceptedAndHasAcceptor() {
			return this.documentItem?.electronicStatus === ElectronicDocumentStatusTypeEnum.ACCEPTED &&
				this.acceptorDetails?.acceptedEmployeeName;
		},
		isDeclinedWithExplanation() {
			return this.documentItem?.electronicStatus === ElectronicDocumentStatusTypeEnum.DECLINED && this.documentItem?.explanation;
		},
		declineExplanation: {
			get() {
				return this.documentItem.explanation;
			},
			set(value) {
				this.setItemExplanation({ index: this.documentIndex, value });
			}
		},
		electronicStatus: {
			get() {
				return this.documentItem.electronicStatus;
			},
			set(value) {
				this.handleSetStatusForElectronicDocument(value);
			}
		},
		paperStatus: {
			get() {
				return this.documentItem.paperStatus;
			},
			set(value) {
				this.handleSetStatusForPaperDocument(value);
			}
		},
		acceptorDetails() {
			if(!this.documentItem.acceptorId) return null;
			
			const employee = this.staffEmployees.find(e => e.id === this.documentItem.acceptorId);
			
			return {
				acceptedEmployeeName: employee.fullName,
				acceptedDate: formatDate(new Date(this.documentItem.acceptedAt), dateTimeFormat) ?? ""
			};
		},
		dropzone() {
			if(this.documentItem.type === BankAccountApplicationDocumentTypeEnum.PAYMENT_ACCEPT_AGREEMENT) {
				return {
					formats: ["pdf", "zip", "rar", "7z"],
					title: this.$t("fields.barDropzone.title.uploadFile"),
					subtitle: this.$t("fields.barDropzone.subtitle.combineFilesForUpload")
				};
			}
			
			return {
				formats: ["pdf"]
			};
		}
	},
	methods: {
		...mapMutations({
			setItemExplanation: mutationTypes.SET_DOCUMENT_FILE_INFO_ITEMS_ITEM_EXPLANATION,
			setItemElectronicStatus: mutationTypes.SET_DOCUMENT_FILE_INFO_ITEMS_ITEM_ELECTRONIC_STATUS,
			setItemPaperStatus: mutationTypes.SET_DOCUMENT_FILE_INFO_ITEMS_ITEM_PAPER_STATUS,
			resetDocumentFileMeta: mutationTypes.RESET_DOCUMENT_FILE_META,
			setDocumentFileMeta: mutationTypes.SET_DOCUMENT_FILE_META,
			setDocumentFileMetaIsLoading: mutationTypes.SET_DOCUMENT_FILE_META_IS_LOADING,
			setIsSigned: mutationTypes.SET_IS_SIGNED,
			setStateSnapshot: mutationTypes.SET_STATE_SNAPSHOT
		}),
		...bankAccountApplicationHelpers.mapMutations({
			setEditableItemStatus: bankAccountApplicationTypes.mutationTypes.SET_EDITABLE_ITEM_STATUS
		}),
		...mapActions({
			updateDocumentStatus: actionTypes.updateDocumentStatus,
			uploadDocumentFileInfo: actionTypes.uploadDocumentFileInfo,
			rollback: actionTypes.cancelChanges,
			updateDocumentComment: actionTypes.updateDocumentComment
		}),
		async handleSetStatusForElectronicDocument(status) {
			this.setItemElectronicStatus({ index: this.documentIndex, value: status });
			
			if(status === ElectronicDocumentStatusTypeEnum.DECLINED) {
				this.handleSaveComment = this.handleSaveDeclineStatus.bind(this);
				this.commentRequired = true;
				this.isCommentDialogOpened = true;
				this.declineStatusExplanationMode = DeclineStatusExplanationDialogMode.EDIT;
			} else {
				const res = await this.updateDocumentStatus({ index: this.documentIndex, type: this.documentItem.type, newStatus: status });
				
				if(res && this.documentItem.type === BankAccountApplicationDocumentTypeEnum.INFORMATIONAL_LETTER &&
					status === ElectronicDocumentStatusTypeEnum.ACCEPTED)
				{
					this.setEditableItemStatus(BankAccountApplicationStatusTypeEnum.IN_PROGRESS_FINANCIAL_CONTROLLER);
				}
			}
		},
		async handleSetStatusForPaperDocument(status) {
			this.setItemPaperStatus({ index: this.documentIndex, value: status });
			await this.updateDocumentStatus({ index: this.documentIndex, type: this.documentItem.type });
		},
		handleOpenCommentDialogInReadMode() {
			this.declineStatusExplanationMode = DeclineStatusExplanationDialogMode.READ;
			this.isCommentDialogOpened = true;
		},
		handleCloseCommentDialog() {
			this.rollback();
			this.$refs[this.refs.form]?.resetValidation?.();
			this.isCommentDialogOpened = false;
		},
		async handleSaveDeclineStatus() {
			await this.updateDocumentStatus({
				index: this.documentIndex,
				type: this.documentItem.type
			});
			this.handleCloseCommentDialog();
		},
		async updateComment() {
			await this.updateDocumentComment({
				index: this.documentIndex,
				type: this.documentItem.type
			});
			this.handleCloseCommentDialog();
		},
		async handleUploadDocumentFile(file, docType) {
			await this.uploadDocumentFile(file, docType);
		},
		async handleUploadSignedDigitalSignatureFile(signature, docType) {
			await this.uploadDocumentFile(this.documentFile, docType, signature);
		},
		async uploadDocumentFile(file, docType, signature = "") {
			if(!file) {
				this.handleDocumentFileDelete();
				return;
			}
			
			this.documentFile = file;
			
			this.setDocumentFileMetaIsLoading(true);
			
			try {
				const { name, type } = file;
				let meta = await barStorageController.createTemperFile(new ApiFile(file, name, docType, type, signature));
				this.setDocumentFileMeta(storageMapper.map(meta, ApiFileMeta, FileMeta));
				
				if(signature) {
					await this.uploadDocumentFileInfo({ tempFileId: this.documentFileMeta.id, index: this.documentIndex });
					
					this.setIsSigned(true);
					this.setStateSnapshot(stateSnapshotKeys.LAST_SAVED);
					this.handleCloseUploadDocumentDialog();
				}
			} catch (error) {
				this.setIsSigned(false);
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			} finally {
				this.setDocumentFileMetaIsLoading(false);
			}
		},
		handleDocumentFileDelete() {
			this.dataUrl = "";
			this.documentFile = null;
			this.resetDocumentFileMeta();
		},
		handleCloseUploadDocumentDialog() {
			this.isUploadDocumentDialogOpened = false;
			this.handleDocumentFileDelete();
			this.$refs[this.refs.form]?.resetValidation?.();
		},
		handleOpenCommentDialog() {
			this.handleSaveComment = this.updateComment.bind(this);
			this.commentRequired = false;
			this.isCommentDialogOpened = true;
			this.declineStatusExplanationMode = DeclineStatusExplanationDialogMode.EDIT;
		}
	},
	created() {
		abortService.initialize();
	},
	components: {
		FrpTextCaption,
		FrpDetailsItem,
		LoanDropzone,
		FrpDigitalSignatureDialog,
		BarDropzone,
		FrpTextarea,
		FrpDialog,
		FrpIcon,
		FrpDateField,
		FrpTextField,
		FrpAutocomplete,
		FrpBtn,
		BarDetailsItem,
		BarDetailsGroup,
		FrpCard
	}
};
</script>

<style>
</style>
