import { ApiHrDepartment } from "@/api/hr/types/apiHrDepartment";
import { ApiHrAdministrationEmployeesItem } from "@/api/hr/types/vacation/apiHrAdministrationEmployeesItem";
import { convertToTimestamp } from "@/utils/dates";
import { ApiHrVacation } from "@/api/hr/types/apiHrVacation";

export interface HrAdministrationEmployeesItem {
	id: string;
	email: string;
	firstName: string;
	lastName: string;
	middleName: string;
	position: string;
	birthday: string;
	mobilePhone: string;
	extensionPhone: string;
	department: ApiHrDepartment;
	personnelNumber?: string;
	employmentDate?: number;
	vacation?: ApiHrVacation;
	plannedVacationDays: number;
	plannedVacationDaysComment: string;
	hasVacationPlan: boolean;
	canCreateUnplannedVacationApplication: boolean;
}

export class HrAdministrationEmployeesItemService {
	static map(source: ApiHrAdministrationEmployeesItem): HrAdministrationEmployeesItem {
		return {
			...source,
			employmentDate: convertToTimestamp(source.employmentDate) as number,
			canCreateUnplannedVacationApplication: source.canCreateUnplannedVacationApplication ?? false
		};
	}
}
