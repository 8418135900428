import SubscribersManager from "@/store/manager/subscribersManager";
import {
	namespace,
	actionTypes,
	mutationTypes,
	getterTypes
} from "@/store/hr/modules/vacationPlanHolidays/types";
import { actionTypes as rootActionTypes } from "@/store/hr/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { resolveAction, resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import { ActionTree, GetterTree, MutationPayload, MutationTree } from "vuex";
import BatchService from "@/services/batchService";
import RouteMixinBuilder from "@/store/shared/route";
import { Store } from "vuex";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import AbortService from "@/services/abortService";
import { RouteNames } from "@/router/hr/routes";
import router from "@/router/hr";
import routeTypes from "@/store/shared/route/types";
import { cloneDeep, first } from "lodash";
import FormMixinBuilder from "@/store/shared/form";
import SnapshotMixinBuilder from "@/store/shared/snapshot";
import SnapshotOptions from "@/store/shared/snapshot/snapshotOptions";
import stateSnapshotKeys from "@/store/shared/snapshot/keys";
import AccessForbiddenException from "@/exceptions/accessForbiddenException";
import { HrController } from "@/api/hr";
import VacationPlanHolidaysRouteService from "@/store/hr/modules/vacationPlanHolidays/services/vacationPlanHolidaysRouteService";
import VacationPlanHolidaysRouteParams from "@/store/hr/modules/vacationPlanHolidays/types/vacationPlanHolidaysRouteParams";
import VacationPlanHolidaysState from "@/store/hr/modules/vacationPlanHolidays/types/vacationPlanHolidaysState";
import VacationPlanHolidaysFilter from "@/store/hr/modules/vacationPlanHolidays/types/vacationPlanHolidaysFilter";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";

const abortService = new AbortService();
const hrController = new HrController(abortService);

const defaultRouteParams = new VacationPlanHolidaysRouteParams();

const routeService = new VacationPlanHolidaysRouteService(defaultRouteParams);

const updateListingBatchService = new BatchService(({ interval: 100 }));

const routeMixin = (new RouteMixinBuilder<VacationPlanHolidaysState>()).build();

const formMixin = (new FormMixinBuilder()).build();
const snapshotMixin = (new SnapshotMixinBuilder({
	options: [
		new SnapshotOptions({
			key: stateSnapshotKeys.LAST_SAVED,
			fields: ["holidays"]
		})
	]
})).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new VacationPlanHolidaysState(
			new VacationPlanHolidaysFilter(),
			routeMixin.state(),
			formMixin.state(),
			snapshotMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();

const state = (new DefaultStateBuilder()).build();

let subscribersManager: SubscribersManager<VacationPlanHolidaysState>;

const getters = <GetterTree<VacationPlanHolidaysState, any>>{
	...formMixin.getters,
	...snapshotMixin.getters,
	[getterTypes.yearValues](state) {
		return state.years.map(x => x.value);
	}
};

let unsubscribeCallback = () => {
};
let store: Store<{}>;

const initializeSubscribersManager = (value: Store<{}>) => {
	store = value;
	subscribersManager = new SubscribersManager<VacationPlanHolidaysState>(store);
};

const subscribe = async (mutation: MutationPayload, rootState: any) => {
	let state = resolveNestedState<VacationPlanHolidaysState>(rootState, namespace);

	switch (mutation.type) {
		case resolveMutation(routeTypes.namespace, routeTypes.mutationTypes.ROUTE_CHANGED):
			if((mutation.payload.from.name === mutation.payload.to.name) && !mutation.payload.to.query.year) {
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.processRoute));
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.reconstituteRoute));
			} else if((mutation.payload.from.name === mutation.payload.to.name) && !state.route.isPushing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.processRoute));
			break;
		case resolveMutation(namespace, mutationTypes.SET_FILTER_YEAR):
		case resolveMutation(namespace, mutationTypes.RESET_FILTER):
		{
			if(!state.route.isProcessing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.pushRoute));

			updateListingBatchService.push(async () => {
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.updateHolidays));
			});

			break;
		}
	}
};

const actions = <ActionTree<VacationPlanHolidaysState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...routeMixin.actions,
	...formMixin.actions,
	...snapshotMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit, state }) {
		await dispatch(actionTypes.initializeBase);

		await dispatch(actionTypes.processRoute);
		await dispatch(actionTypes.reconstituteRoute);

		unsubscribeCallback = subscribersManager.subscribe(subscribe);

		await dispatch(actionTypes.fetchYears);
		await dispatch(actionTypes.updateHolidays);

		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.updateHolidays]({ commit, state }) {
		if(!state.filter.year)
			return;

		const holidays = state.years.find(x => x.value === state.filter.year)!.holidays;

		commit(mutationTypes.SET_HOLIDAYS, holidays.sort());
		commit(mutationTypes.SET_STATE_SNAPSHOT, stateSnapshotKeys.LAST_SAVED);
	},
	async [actionTypes.processRoute]({ rootState, commit, dispatch, state }) {
		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, true);

		let routeParams = await routeService.resolveRouteParams(rootState.route.params);

		if(state.filter.year !== routeParams.year)
			commit(mutationTypes.SET_FILTER_YEAR, routeParams.year);

		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, false);
	},
	async [actionTypes.pushRoute]({ state, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);

		await router.push({
			name: RouteNames.VACATION_PLANS_ADMINISTRATION_HOLIDAYS,
			params: routeService.resolveRouteParamsDictionary(state)
		}).catch(() => {
		});

		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.reconstituteRoute]({ state, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);

		await router.replace({
			name: RouteNames.VACATION_PLANS_ADMINISTRATION_HOLIDAYS,
			params: routeService.resolveRouteParamsDictionary(state)
		}).catch(() => {
		});

		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.destroy]({ dispatch }) {
		unsubscribeCallback();
		await dispatch(actionTypes.destroyBase);
	},
	async [actionTypes.fetchYears]({ commit, state, dispatch }) {
		commit(mutationTypes.SET_IS_YEARS_LOADING, true);

		try {
			const items = await hrController.getAdministrationYears();

			commit(mutationTypes.SET_YEARS, items);

			if(!router.currentRoute.params.year || !items.some(x => x.value === +router.currentRoute.params.year)) {
				const year = first(items);

				commit(mutationTypes.SET_FILTER_YEAR, year?.value);
			}
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_YEARS_LOADING, false);
		}
	},
	async [actionTypes.save]({ commit, state, dispatch }) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);

		try {
			const { holidays } = await hrController.saveYear(String(state.filter.year), state.holidays);

			alertService.addInfo(AlertKeys.SUCCESS_UPDATED_INFO);

			commit(mutationTypes.SET_HOLIDAYS, holidays);
			commit(mutationTypes.SET_YEAR_HOLIDAYS, { year: state.filter.year, value: holidays });
			commit(mutationTypes.SET_STATE_SNAPSHOT, stateSnapshotKeys.LAST_SAVED);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	},
	async [actionTypes.createYear]({ commit, state, dispatch }) {
		commit(mutationTypes.SET_IS_YEAR_CREATING, true);

		try {
			const { value } = await hrController.saveYear(String(state.filter.year ? state.filter.year + 1 : new Date().getFullYear()), []);

			alertService.addInfo(AlertKeys.YEAR_SUCCESS_CREATED);

			await dispatch(actionTypes.fetchYears);

			await router.push({ name: RouteNames.VACATION_PLANS_ADMINISTRATION_HOLIDAYS, params: { year: String(value) } }).catch(() => {
			});
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_YEAR_CREATING, false);
		}
	}
};

const mutations = <MutationTree<VacationPlanHolidaysState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...routeMixin.mutations,
	...formMixin.mutations,
	...snapshotMixin.mutations,
	[mutationTypes.SET_FILTER_YEAR](state, value) {
		state.filter.year = value;
	},
	[mutationTypes.RESET_FILTER](state) {
		state.filter = new VacationPlanHolidaysFilter(first(state.years)?.value);
	},
	[mutationTypes.SET_IS_YEARS_LOADING](state, value) {
		state.isYearsLoading = value;
	},
	[mutationTypes.SET_IS_YEAR_CREATING](state, value) {
		state.isYearCreating = value;
	},
	[mutationTypes.SET_YEARS](state, value) {
		state.years = value;
	},
	[mutationTypes.SET_HOLIDAYS](state, value) {
		state.holidays = value;
	},
	[mutationTypes.SET_YEAR_HOLIDAYS](state, { year, value }) {
		state.years[state.years.findIndex(x => x.value === year)].holidays = cloneDeep(value);
	}
};

export {
	namespace, state, getters, actions, mutations, initializeSubscribersManager
};

const vacationPlanHolidaysModule = {
	namespace, state, getters, actions, mutations, initializeSubscribersManager, namespaced: true
};

export default vacationPlanHolidaysModule;
