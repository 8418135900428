import IPageState from "@/store/shared/base/types/pageState";
import { ApiHrEmployee } from "@/api/hr/types/apiHrEmployee";

export default class EmployeeProfileState implements IPageState {
	constructor(
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isLoading: boolean = false,
		public isImageUploading: boolean = false,
		public employee: ApiHrEmployee = {} as ApiHrEmployee
	)
	{
	}
}
