import { actionTypes, mutationTypes, namespace } from "@/store/hr/modules/employee/modules/profile/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import AbortService from "@/services/abortService";
import { HrController } from "@/api/hr";
import router from "@/router/hr";
import { actionTypes as rootActionTypes } from "@/store/hr/types";
import EmployeeProfileState from "@/store/hr/modules/employee/modules/profile/types/employeeProfileState";
import { HrEmployeeMapper } from "@/types/hr/hrEmployee";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import { HrStorageController } from "@/api/hr/storage";
import ApiFile from "@/api/types/storage/apiFile";
import { dataURLtoFile } from "@/utils/file";

const abortService = new AbortService();
const hrController = new HrController(abortService);
const hrStorageController = new HrStorageController(abortService);

class DefaultStateBuilder {
	constructor() {
	}
	
	build() {
		return new EmployeeProfileState(
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<EmployeeProfileState, any>>{};

const actions = <ActionTree<EmployeeProfileState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit }) {
		await dispatch(actionTypes.initializeBase);
		
		await dispatch(actionTypes.fetch);
		
		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.fetch]({ dispatch, commit }) {
		commit(mutationTypes.SET_IS_LOADING, true);
		
		try {
			const employee = await hrController.getEmployeeProfile(router.currentRoute.params.id);
			
			commit(mutationTypes.SET_EMPLOYEE, HrEmployeeMapper.map(employee));
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_LOADING, false);
		}
	},
	async [actionTypes.tryUploadProfileImage]({ commit, dispatch }, { dataUrl, fileName }: { dataUrl: string, fileName: string }) {
		commit(mutationTypes.SET_IS_IMAGE_UPLOADING, true);
		
		try {
			const file = dataURLtoFile(dataUrl, fileName);
			
			const { id } = await hrStorageController.createTemperFile(new ApiFile(file, file.name, "avatar", file.type, ""));
			
			const { pictureFileId } = await hrController.uploadProfilePhoto(router.currentRoute.params.id, id);
			
			commit(mutationTypes.SET_EMPLOYEE_PICTURE_FILE_ID, pictureFileId);
			alertService.addInfo(AlertKeys.PROFILE_PHOTO_SUCCESS_UPLOADED);
			
			return true;
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
			
			return false;
		} finally {
			commit(mutationTypes.SET_IS_IMAGE_UPLOADING, false);
		}
	}
};

const mutations = <MutationTree<EmployeeProfileState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	[mutationTypes.SET_IS_LOADING](state, value) {
		state.isLoading = value;
	},
	[mutationTypes.SET_EMPLOYEE](state, value) {
		state.employee = value;
	},
	[mutationTypes.SET_EMPLOYEE_PICTURE_FILE_ID](state, value) {
		state.employee.pictureFileId = value;
	},
	[mutationTypes.SET_IS_IMAGE_UPLOADING](state, value) {
		state.isImageUploading = value;
	}
};

export {
	namespace, state, getters, actions, mutations
};

const employeeProfileModule = {
	namespace, state, getters, actions, mutations, namespaced: true
};

export default employeeProfileModule;
