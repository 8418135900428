import { ApiHrVacation } from "@/api/hr/types/apiHrVacation";
import { ApiHrDepartment } from "@/api/hr/types/apiHrDepartment";
import { ApiHrEmployee } from "@/api/hr/types/apiHrEmployee";
import { convertToTimestamp } from "@/utils/dates";
import { ApiHrHead } from "@/api/hr/types/apiHrHead";

export interface HrEmployee {
	id: string;
	firstName: string;
	lastName: string;
	middleName: string;
	position: string;
	department: ApiHrDepartment;
	extensionPhone: string;
	mobilePhone: string;
	email: string;
	birthday: number;
	employmentDate: number;
	status: string;
	pictureFileId?: string;
	vacation?: ApiHrVacation;
	head: ApiHrHead;
}

export class HrEmployeeMapper {
	static map(source: ApiHrEmployee): HrEmployee {
		return {
			...source,
			birthday: convertToTimestamp(source.birthday) as number,
			employmentDate: convertToTimestamp(source.employmentDate) as number
		};
	}
}
