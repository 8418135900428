<template>
	<frp-card v-if="isInitialized" :title="$t('titles.control')" class="pb-1">
		<template #content>
			<bar-details-group>
				<bar-details-item :title="$t('details.titles.responsible')">
					<frp-autocomplete v-model="responsibleUser"
									  :items="bankResponsibleUsers"
									  item-text="fullName"
									  item-value="id"
									  color="blue"
									  :disabled="isBankAccountSaving || isBankAccountControlEditAccessDenied"
									  class="bar-field bar-field--width"
									  hide-details
									  required
									  :placeholder="$t('fields.responsibleUser.placeholder')">
					</frp-autocomplete>
				</bar-details-item>
				<bar-details-item :title="$t('details.titles.controlDate')">
					<frp-date-field v-model="controlDate"
									hide-details
									class="bar-field"
									width="270px"
									:disabled="isBankAccountSaving || isBankAccountControlEditAccessDenied"
									:placeholder="$t('fields.bankAccountControlDate.placeholder')">
					</frp-date-field>
				</bar-details-item>
			</bar-details-group>
		</template>
	</frp-card>
	<bar-bank-account-control-loader v-else/>
</template>

<script>
import FrpCard from "@/components/cards/FrpCard.vue";
import BarDetailsGroup from "@/components/details/BarDetailsGroup.vue";
import BarDetailsItem from "@/components/details/BarDetailsItem.vue";
import FrpAutocomplete from "@/components/fields/FrpAutocomplete.vue";
import FrpDateField from "@/components/fields/FrpDateField.vue";
import authorizationMixin from "@/mixins/authorizationMixin";
import colorsMixin from "@/mixins/colorsMixin";
import storeModuleBasedPage from "@/mixins/storeModuleBasedPage";
import { RouteNames } from "@/router/bar/routes";
import { namespace } from "@/store/bar/modules/bankAccount";
import { getterTypes, mutationTypes } from "@/store/bar/modules/bankAccount/types";
import BarBankAccountControlLoader from "@/views/bar/bankAccount/sections/control/BarBankAccountControlLoader.vue";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

export default {
	mixins: [storeModuleBasedPage, colorsMixin, authorizationMixin],
	data() {
		return {
			namespace,
			RouteNames
		};
	},
	computed: {
		...mapState({
			isInitialized: state => state.isInitialized,
			isBankAccountSaving: state => state.isBankAccountSaving,
			bankResponsibleUsers: state => state.bankResponsibleUsers,
			editableItem: state => state.editableItem
		}),
		...mapGetters({
		}),
		isBankAccountControlEditAccessDenied() {
			return !this.can(
				[
					this.Permissions.BAR_ACCOUNT_CONTROL_UPDATE,
					this.Permissions.BAR_ACCOUNT_CONTROL_CREATE,
					this.Permissions.BAR_ACCOUNT_UPDATE
				]
			)
		},
		responsibleUser: {
			get() {
				return this.editableItem.controlResponsibleEmployeeId;
			},
			set(value) {
				this.setEditableItemControlResponsibleEmployeeId(value);
			}
		},
		controlDate: {
			get() {
				return this.editableItem.controlDate;
			},
			set(value) {
				this.setEditableItemControlDate(value);
			}
		}
	},
	methods: {
		...mapMutations({
			setEditableItemControlResponsibleEmployeeId: mutationTypes.SET_EDITABLE_ITEM_CONTROL_RESPONSIBLE_USER_ID,
			setEditableItemControlDate: mutationTypes.SET_EDITABLE_ITEM_CONTROL_DATE
		}),
		...mapActions({})
	},
	components: { BarBankAccountControlLoader, FrpDateField, FrpAutocomplete, BarDetailsItem, BarDetailsGroup, FrpCard }
};
</script>

<style scoped>

</style>
