import { IsIn, IsInt, IsString, Max, Min, IsEnum } from "class-validator";
import { BankAccountApplicationStatusTypeEnum } from "@/store/bar/types/BankAccountApplicationStatusTypeEnum";

export default class BankAccountApplicationsListRouteQuery {
	@IsInt()
	@Min(0)
	@Max(1000)
	page: Number;

	@IsString()
	loanApplicationNumber: String;

	@IsString()
	counterpartyId: String;

	@IsString()
	@IsIn([...Object.values(BankAccountApplicationStatusTypeEnum), ""])
	status: String;

	constructor(
		page: Number,
		loanApplicationNumber: String,
		counterpartyId: String,
		status: String
	)
	{
		this.page = page;
		this.loanApplicationNumber = loanApplicationNumber;
		this.counterpartyId = counterpartyId;
		this.status = status;
	}
}
