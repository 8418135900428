export enum BarRolesTypeEnum {
	ADMINISTRATOR = "Administrator",
	/** Сотрудник УФКП */
	PROJECT_FINANCIAL_CONTROL_DEPARTMENT_MANAGER = "ProjectFinancialControlDepartmentManager",
	/** Заемщик */
	BORROWER = "Borrower",
	/** Сотрудник ДСП */
	CURATORS_DEPARTMENT_MANAGER = "CuratorsDepartmentManager",
	/** Сотрудник ЮД */
	LAWYER_MANAGER = "LawyerManager",
}
